import { AddIcon, ArrowIcon, AttachmentIcon } from "../../Assets/assets";
import { isValidObject } from "../../Services/validators";
import { dateAndTimeConverter } from "../../Utils/constants";
import Button from "../Button/Button";
import ConditionalRender from "../ConditionalRender/ConditionalRender";
import noPetitionsFallbackIcon from "../../Assets/noPetitionsFallbackIcon.svg";

export default function Petitions(props) {
  return (
    <div
      className="inherit-parent-height flex-justify-content-space-between flex-direction-column"
      data-cy={`petition-screen`}>
      <ConditionalRender condition={isValidObject(props.petitions?.data)}>
        <div className="overflow-scroll inherit-parent-height">
          {props.petitions?.data &&
            Object.values(props.petitions.data)
              ?.sort((a, b) => {
                return b.status.updatedAt - a.status.updatedAt;
              })
              .map((data, index) => (
                <div
                  className=" font-family-RHD-medium background-white margin-bottom-medium padding-medium display-flex border-radius-2px gradient-border"
                  key={index}
                  onClick={() => {
                    props.petitionOnclick(data.documentId);
                  }}
                  data-cy={`petition-${data.documentId}`}>
                  <div style={{ width: "calc(100% - 16px)" }}>
                    <div className=" font-size-small display-flex flex-align-items-center font-color-tertiary  ">
                      <div>#{data.documentId}</div>
                      <div className="  dot-4px margin-right-small margin-left-small" />
                      <div>
                        {dateAndTimeConverter(data.createdAt, "secondDate")}
                      </div>
                      <ConditionalRender condition={data.assets?.length > 0}>
                        <div className="  dot-4px margin-right-small margin-left-small" />
                        <AttachmentIcon /> {data.assets?.length}
                      </ConditionalRender>
                    </div>

                    <div className="line-clamp  padding-top-default font-size-medium">
                      {data.description}
                    </div>

                    <div className="  margin-top-default padding-top-small padding-bottom-small padding-left-default padding-right-default border-radius-default font-size-small background-color-grey width-fit-content">
                      {data.status.currentStatus}
                    </div>
                  </div>

                  <ArrowIcon />
                </div>
              ))}
        </div>
      </ConditionalRender>

      <ConditionalRender condition={!isValidObject(props.petitions.data)}>
        <div
          className=" inherit-parent-height inherit-parent-width flex-justify-content-center flex-align-items-center flex-direction-column"
          data-cy={"no-petition-fallback"}>
          <img src={noPetitionsFallbackIcon} alt="Fallback icon" />

          <div className=" padding-top-larger font-family-RHD-medium font-size-larger text-align-center">
            {props.languageData.noPetitionsPosted}
          </div>

          <div className=" font-size-medium text-align-center padding-top-default">
            {props.languageData.noPetitionsFallbackDescription}
          </div>
        </div>
      </ConditionalRender>

      <div className="">
        <Button
          data-cy={"create-petition-button"}
          type={"button"}
          className=" margin-top-large"
          text={
            <div className=" flex-align-items-center flex-justify-content-center">
              <AddIcon color={"white"} />
              <div className=" padding-left-default">
                {props.languageData.newPetition.toUpperCase()}
              </div>
            </div>
          }
          onClick={() => {
            props.newPetitionOnclick();
          }}
          disabled={false}
          boxShadow={false}
        />
      </div>
    </div>
  );
}
