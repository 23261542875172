import english from "../../Language/english.json";
import tamil from "../../Language/tamil.json";

const languageData = {
  english,
  tamil,
};

export const useLanguage = (language) => {
  return languageData?.[language];
};
