import { all, put, takeEvery } from "redux-saga/effects";
import { setErrorStatus } from "../status/action";
import { updateVolunteering } from "../../Services/database";
import store from "../store";
import { getFileUrl } from "../../Services/storage";
import { getVolunteeringAssets } from "./action";

export const actionTypes = {
  UPDATE_OPTING: "UPDATE_OPTING",
  PUT_VOLUNTEERING_DATA: "PUT_VOLUNTEERING_DATA",
  GET_VOLUNTEERING_ASSETS: "GET_VOLUNTEERING_ASSETS",
};

function* updateOptingWorker(action) {
  try {
    yield setVolunteerLoading(true);

    yield updateVolunteering({
      profileId: store.getState().profile?.data?.documentId,
      data: {
        volunteering: {
          currentStatus: action.payload.optingStatus,
          updatedAt: +new Date(),
        },
      },
    });

    yield setVolunteerLoading(false);
  } catch (error) {
    yield setVolunteerLoading(false);
    setErrorStatus(error);
  }
}

function* setVolunteeringDataWorker(action) {
  try {
    yield setVolunteerLoading(true);

    yield put({
      type: "SET_VOLUNTEERING_DATA",
      payload: {
        data: action.payload.data,
      },
    });

    yield setVolunteerLoading(false);

    Object.values(action.payload.data)?.forEach((data) => {
      getVolunteeringAssets(data);
    });
  } catch (error) {
    yield setVolunteerLoading(false);
    setErrorStatus(error);
  }
}

function* getVolunteeringAssetsWorker(action) {
  try {
    // yield setVolunteerLoading(true);

    yield put({
      type: "UPDATE_VOLUNTEERING_DATA",
      payload: {
        [action.payload.data.documentId]: {
          ...action.payload.data,
          thumbnailSrc: yield getFileUrl(action.payload.data.thumbnail),
        },
      },
    });

    // yield setVolunteerLoading(false);
  } catch (error) {
    yield setVolunteerLoading(false);
    setErrorStatus(error);
  }
}

export default function* volunteerWatcher() {
  yield all([
    takeEvery("UPDATE_OPTING", updateOptingWorker),
    takeEvery("PUT_VOLUNTEERING_DATA", setVolunteeringDataWorker),
    takeEvery("GET_VOLUNTEERING_ASSETS", getVolunteeringAssetsWorker),
  ]);
}

function* setVolunteerLoading(bool) {
  yield put({
    type: "SET_VOLUNTEER_LOADING",
    payload: {
      loading: bool,
    },
  });
}
