export const throwError = (code, message) => {
  let instance = new Error(code, message);
  instance.code = code;
  instance.message = message;
  return instance;
};

export function errorHandler(error) {
  console.error(error);
  let result = {};
  switch (error.code) {
    case "auth":
      result.code = error.code;
      result.message = {
        english: error.message,
        tamil: error.message,
      };
      break;

    case "auth/timeout":
      result.code = error.code;
      result.message = {
        english: "Request timed out. Please try again later",
        tamil: "கோரிக்கை நேரம் முடிந்தது. தயவுசெய்து பிறகு முயற்சிக்கவும்",
      };
      break;

    case "auth/invalid-credential":
    case "auth/invalid-verification-code":
    case "auth/invalid-verification-id":
      result.code = error.code;
      result.message = {
        english: "Invalid credentials. Please check your OTP",
        tamil: "தவறான OTP",
      };
      break;

    case "auth/captcha-check-failed":
      result.code = error.code;
      result.message = {
        english: "Captcha assessment failed. Please try again",
        tamil: "கேப்ட்சா மதிப்பீடு தோல்வியடைந்தது. மீண்டும் முயற்சிக்கவும்",
      };
      break;

    case "auth/invalid-phone-number":
    case "auth/missing-phone-number":
      result.code = error.code;
      result.message = {
        english: "Please enter a valid phone number",
        tamil: "சரியான தொலைபேசி எண்ணை உள்ளிடவும்",
      };
      break;

    case "auth/quota-exceeded":
    case "auth/too-many-requests":
      result.code = error.code;
      result.message = {
        english: "Usage limit exceeded. Please try again later",
        tamil: "பயன்பாட்டு வரம்பு முடிந்துவிட்டது. மீண்டும் முயற்சிக்கவும்",
      };
      break;

    case "auth/invalid-user-token":
    case "auth/user-token-expired":
    case "auth/id-token-expired":
    case "auth/id-token-revoked":
    case "unauthenticated":
      result.code = error.code;
      result.message = {
        english: "Session expired. Please login",
        tamil: "அமர்வு காலாவதியானது. மீண்டும் உள்நுழையவும்",
      };
      break;

    case "auth/network-request-failed":
      result.code = error.code;
      result.message = {
        english: "Connectivity error. Please try again later",
        tamil: "இணைப்பு பிழை. தயவுசெய்து பிறகு முயற்சிக்கவும்",
      };
      break;

    case "auth/requires-recent-login":
      result.code = error.code;
      result.message = {
        english: "Session duration too long. Please re-login",
        tamil: "அமர்வு காலாவதியானது. தயவுசெய்து மீண்டும் உள்நுழையவும்",
      };
      break;

    case "auth/web-storage-unsupported":
      result.code = error.code;
      result.message = {
        english: "Unsupported web browser",
        tamil: "ஆதரிக்கப்படாத இணைய உலாவி",
      };
      break;

    //input error
    case "input/image-size-exceeded":
      result.code = error.code;
      result.message = {
        english: "Image size should be less than 2MB",
        tamil: "படத்தின் அளவு 2MB க்கும் குறைவாக இருக்க வேண்டும்",
      };
      break;

    case "input/file-invalid":
      result.code = error.code;
      result.message = {
        english: "Kindly upload a valid File",
        tamil: "தயவுசெய்து சரியான ஆவணத்தைப் பதிவேற்றவும்",
      };
      break;

    case "input/file-size-exceeded":
      result.code = error.code;
      result.message = {
        english: "Document size should be less than 5MB",
        tamil: "ஆவணத்தின் அளவு 5MB க்கும் குறைவாக இருக்க வேண்டும்",
      };
      break;
    //custom error from frontend
    case "custom":
      result.code = error.code;
      result.message = {
        english: error.message.english,
        tamil: error.message.tamil,
      };
      break;

    //firestore database error codes
    case "cancelled":
    case "deadline-exceeded":
    case "not-found":
    case "already-exists":
    case "permission-denied":
    case "resource-exhausted":
    case "failed-precondition":
    case "aborted":
    case "out-of-range":
    case "unimplemented":
    case "internal":
    case "unavailable":
    case "invalid-argument":
      result.code = `database/${error.code}`;
      result.message = {
        english: "Something went wrong. Please try again later",
        tamil: "தயவுசெய்து பிறகு முயற்சிக்கவும்",
      };
      break;

    case "data-loss":
      result.code = `database/${error.code}`;
      result.message = {
        english: "Data corrupted. Please try again later",
        tamil: "தகவல்கள் சிதைந்துள்ளது, தயவுசெய்து பிறகு முயற்சிக்கவும்",
      };
      break;

    case "search":
      result.code = `database/${error.code}`;
      result.message = { english: error.message, tamil: error.message };
      break;

    //form validation errors
    case "input":
      result.code = `input`;
      result.message = { english: error.message, tamil: error.message };
      break;

    // Storage errors
    case "object-not-found":
    case "bucket-not-found":
    case "unauthorized":
    case "retry-limit-exceeded":
    case "invalid-event-name":
    case "invalid-url":
    case "server-file-wrong-size":
      result.code = `storage/${error.code}`;
      result.message = {
        english: "Something went wrong. Please try again later",
        tamil: "தயவுசெய்து பிறகு முயற்சிக்கவும்",
      };
      break;

    case "canceled":
      result.code = `storage/${error.code}`;
      result.message = {
        english: "Process Cancelled",
        tamil: "செயல்முறை ரத்து செய்யப்பட்டது",
      };
      break;

    case "400":
      result.code = `api/${error.code}`;
      result.message = { english: error.message, tamil: error.message };
      break;

    default:
      if (
        error.code === undefined ||
        !error.code.includes("auth") ||
        !error.code.includes("database") ||
        !error.code.includes("input") ||
        !error.code.includes("storage")
      ) {
        result.code = `unknown/${error.code}`;
      } else {
        result.code = error.code;
      }
      result.message = {
        english: "Something went wrong. Please try again later",
        tamil: "தயவுசெய்து பிறகு முயற்சிக்கவும்",
      };
      break;
  }
  return result;
}
