import React from "react";
import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import VolunteeringFallbackIcon from "../../Assets/VolunteeringFallbackIcon.svg";

export default function OptOutModal(props) {
  return (
    <Modal
      show={true}
      canIgnore={true}
      onClose={() => {
        props.navigate(-1);
      }}
      width="inherit-parent-width"
      position=" absolute-center-self-horizontally bottom-0 "
      maxWidth="max-width-630px"
      background="false"
      boxShadow="false"
      borderRadius="false"
      height="height-fit-to-content"
    >
      <div
        className="font-color-secondary font-family-gilroy-medium font-color-secondary text-align-center padding-larger background-white"
        data-cy={"optOut-confirmation-modal"}
      >
        <img src={VolunteeringFallbackIcon} alt="volunteering icon" />
        
        <div className=" padding-top-default font-family-RHD-medium font-size-large">
          {props.languageData?.OptOutVolunteerRequests}
        </div>
        <div className=" padding-top-default font-size-medium">
          {props.languageData?.OptOutDescription}
        </div>

        <div className="display-flex padding-top-larger ">
          <Button
            boxShadow={false}
            type="submit"
            variant={"secondary"}
            text={props.languageData?.optOut.toUpperCase()}
            className={"border-1px-e5e5e5 margin-right-small font-color-red"}
            onClick={() => {
              props.updateOpting("OPTEDOUT");
              props.navigate(-1);
            }}
            data-cy={"confirm-optOut-button"}
          />
          <Button
            boxShadow={false}
            text={props.languageData?.no.toUpperCase()}
            variant={"secondary"}
            onClick={() => {
              props.navigate(-1);
            }}
            className={
              "font-color-secondary border-1px-e5e5e5 margin-left-small"
            }
            data-cy={"cancel-optOut-button"}
          />
        </div>
      </div>
    </Modal>
  );
}
