import { useEffect, useState } from "react";
import {
  query,
  collection,
  where,
  onSnapshot,
  addDoc,
  updateDoc,
  doc,
  setDoc,
} from "firebase/firestore";
import { database } from "./firebase";
import { setErrorStatus } from "../Redux/status/action";
import { setAccountProfiles, setReferrals } from "../Redux/profile/action";
import { setPetitions } from "../Redux/petitions/action";
import { setAnnouncements } from "../Redux/announcements/action";
import { setVolunteeringData } from "../Redux/volunteer/action";
import { setJobsData } from "../Redux/jobs/action";

export function useProfileListener(props) {
  const [profileListener, setProfileListener] = useState({
    listener: null,
  });

  const subscribeToProfile = (phoneNumber) => {
    if (phoneNumber) {
      const profileQuery = query(
        collection(database, "citizens"),
        where("phoneNumber", "==", phoneNumber)
      );

      return onSnapshot(
        profileQuery,
        (dataSnapshot) => {
          let profiles = {};
          dataSnapshot.forEach((doc) => {
            profiles = {
              ...doc.data(),
              documentId: doc.id,
            };
          });
          setAccountProfiles(profiles);
        },
        (error) => {
          console.error(error, "from profile");
          setErrorStatus(error);
        }
      );
    } else {
      return;
    }
  };
  useEffect(() => {
    if (
      props.isAuth === true &&
      typeof props.phoneNumber === "string" &&
      typeof props.uid === "string" &&
      profileListener.listener === null
    ) {
      setProfileListener({
        listener: subscribeToProfile(props.phoneNumber),
      });
    } else if (
      props.isAuth === false &&
      typeof profileListener.listener === "function"
    ) {
      profileListener.listener();
      setProfileListener({
        listener: null,
      });
    }
    // eslint-disable-next-line
  }, [props.isAuth, props.phoneNumber, props.uid]);
}

export function useReferralListener(props) {
  const [referralListener, setReferralListener] = useState({
    listener: null,
  });

  const subscribeToReferral = (profileId, phoneNumber) => {
    if (phoneNumber && profileId) {
      const referralQuery = query(
        collection(database, "citizens", profileId, "referralSummary"),
        where("citizen.phoneNumber", "==", phoneNumber)
      );

      onSnapshot(
        referralQuery,
        (dataSnapshot) => {
          let referrals = {};
          dataSnapshot.forEach((doc) => {
            referrals[doc.id] = {
              ...doc.data(),
              documentId: doc.id,
            };
          });
          setReferrals(referrals);
        },
        (error) => {
          console.error(error, "from referral");
          setErrorStatus(error);
        }
      );
    } else {
      return;
    }
  };

  useEffect(() => {
    if (
      props.isAuth === true &&
      typeof props.phoneNumber === "string" &&
      typeof props.profileId === "string" &&
      referralListener.listener === null
    ) {
      setReferralListener({
        listener: subscribeToReferral(props.profileId, props.phoneNumber),
      });
    } else if (
      props.isAuth === false &&
      typeof referralListener.listener === "function"
    ) {
      referralListener.listener();
      setReferralListener({
        listener: null,
      });
    }
    // eslint-disable-next-line
  }, [props.isAuth, props.phoneNumber, props.profileId]);
}

export function usePetitionsListener(props) {
  const [petitionsListener, setPetitionsListener] = useState({
    listener: null,
  });

  const subscribeToProfile = (phoneNumber) => {
    if (phoneNumber) {
      const profileQuery = query(
        collection(database, "petitions"),
        where("issuedBy.userDetails.phoneNumber", "==", phoneNumber)
      );

      return onSnapshot(
        profileQuery,
        (dataSnapshot) => {
          let petitions = {};
          dataSnapshot.forEach((doc) => {
            petitions[doc.id] = {
              ...doc.data(),
              documentId: doc.id,
            };
          });
          setPetitions(petitions);
        },
        (error) => {
          console.error(error, "from petitions");
          setErrorStatus(error);
        }
      );
    } else {
      return;
    }
  };
  useEffect(() => {
    if (
      props.isAuth === true &&
      props.isProfileTrue === true &&
      typeof props.phoneNumber === "string" &&
      petitionsListener.listener === null
    ) {
      setPetitionsListener({
        listener: subscribeToProfile(props.phoneNumber),
      });
    } else if (
      props.isAuth === false &&
      typeof petitionsListener.listener === "function"
    ) {
      petitionsListener.listener();
      setPetitionsListener({
        listener: null,
      });
    }
    // eslint-disable-next-line
  }, [props.isAuth, props.phoneNumber, props.isProfileTrue]);
}

export function useAnnouncementsListener(props) {
  const [announcementListener, setAnnouncementListener] = useState({
    listener: null,
  });

  const subscribeToAnnouncement = (phoneNumber) => {
    if (phoneNumber) {
      const announcementQuery = query(collection(database, "announcements"));

      return onSnapshot(
        announcementQuery,
        (dataSnapshot) => {
          let announcements = {};
          dataSnapshot.forEach((doc) => {
            announcements[doc.id] = {
              ...doc.data(),
              documentId: doc.id,
            };
          });
          setAnnouncements(announcements);
        },
        (error) => {
          console.error(error, "from announcements");
          setErrorStatus(error);
        }
      );
    } else {
      return;
    }
  };
  useEffect(() => {
    if (
      props.isAuth === true &&
      props.isProfileTrue === true &&
      typeof props.phoneNumber === "string" &&
      announcementListener.listener === null
    ) {
      setAnnouncementListener({
        listener: subscribeToAnnouncement(props.phoneNumber),
      });
    } else if (
      props.isAuth === false &&
      typeof announcementListener.listener === "function"
    ) {
      announcementListener.listener();
      setAnnouncementListener({
        listener: null,
      });
    }
    // eslint-disable-next-line
  }, [props.isAuth, props.phoneNumber, props.isProfileTrue]);
}

export function useVolunteeringListener(props) {
  const [volunteeringListener, setVolunteeringListener] = useState({
    listener: null,
  });

  const subscribeToVolunteering = () => {
    const volunteeringQuery = query(collection(database, "volunteering"));

    return onSnapshot(
      volunteeringQuery,
      (dataSnapshot) => {
        let volunteering = {};
        dataSnapshot.forEach((doc) => {
          volunteering[doc.id] = {
            ...doc.data(),
            documentId: doc.id,
          };
        });
        setVolunteeringData(volunteering);
      },
      (error) => {
        console.error(error, "from volunteering");
        setErrorStatus(error);
      }
    );
  };
  useEffect(() => {
    if (
      props.isAuth === true &&
      props.volunteeringStatus === "OPTEDIN" &&
      volunteeringListener.listener === null
    ) {
      setVolunteeringListener({
        listener: subscribeToVolunteering(),
      });
    } else if (
      (props.isAuth === false || props.volunteeringStatus === "OPTEDOUT") &&
      typeof volunteeringListener.listener === "function"
    ) {
      volunteeringListener.listener();
      setVolunteeringListener({
        listener: null,
      });
      setVolunteeringData({});
    }
    // eslint-disable-next-line
  }, [props.isAuth, props.volunteeringStatus]);
}

export function useJobsListener(props) {
  const [jobsListener, setJobsListener] = useState({
    listener: null,
  });

  const subscribeToJobs = () => {
    const jobsQuery = query(collection(database, "jobs"));

    return onSnapshot(
      jobsQuery,
      (dataSnapshot) => {
        let jobs = {};
        dataSnapshot.forEach((doc) => {
          jobs[doc.id] = {
            ...doc.data(),
            documentId: doc.id,
          };
        });
        setJobsData(jobs);
      },
      (error) => {
        console.error(error, "from jobs");
        setErrorStatus(error);
      }
    );
  };
  useEffect(() => {
    if (
      props.isAuth === true &&
      props.isProfileTrue === true &&
      jobsListener.listener === null
    ) {
      setJobsListener({
        listener: subscribeToJobs(),
      });
    } else if (
      props.isAuth === false &&
      typeof jobsListener.listener === "function"
    ) {
      jobsListener.listener();
      setJobsListener({
        listener: null,
      });
    }
    // eslint-disable-next-line
  }, [props.isAuth, props.isProfileTrue]);
}

export async function createCitizen(data) {
  await addDoc(collection(database, "citizens"), data);
}

export async function updateVolunteering({ profileId, data }) {
  await updateDoc(doc(database, "citizens", profileId), data);
}

export async function updateFileURLToCurrentUser(documentId, fileURL) {
  const employeeRef = doc(database, "citizens", documentId);
  await updateDoc(employeeRef, { resumeUrl: fileURL });
}

export async function checkAndAddRegData(deviceId, data) {
  console.log(deviceId, data, "deviceId,   data");
  const docRef = doc(database, "devices", deviceId);
  await setDoc(docRef, data);
  console.log("Device Document added");
}

export async function updateRegData(deviceId, data) {
  console.log(deviceId, data, "deviceId,  data");
  const docRef = doc(database, "devices", deviceId);
  await updateDoc(docRef, data);
  console.log("Device Document updated");
}
