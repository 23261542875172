import React from "react";
import Modal from "../Modal/Modal";
import PDFRenderer from "../PDFRenderer/PDFRenderer";

export default function ViewAssetModal(props) {
  return (
    <Modal
      show={true}
      canIgnore={true}
      onClose={() => {
        props.navigate(-1);
      }}
      width="inherit-parent-width"
      maxWidth="max-width-630px"
      background="false"
      boxShadow="false"
      borderRadius="false"
      height="height-fit-to-content">
      {props.previewFile.type === "image" && (
        <div data-cy="image-view-modal" className={`inherit-parent-height`}>
          <img
            className="inherit-parent-width"
            data-cy="view-proof-image"
            src={props.previewFile.data}
            alt="images"
          />
        </div>
      )}
      {props.previewFile.type === "pdf" && (
        <div style={{ height: "80%", overflow: "scroll" }}>
          <PDFRenderer
            readOnly={true}
            pdfArrayBuffer={
              props.PDFArrayBuffer?.[props.previewFile.data] &&
              props.PDFArrayBuffer?.[props.previewFile.data]
            }
            saveWithBackground={false}
            onPDFChange={() => {
              props.onPDFChange();
            }}
            onRender={() => {
              props.onPdfRender();
            }}
          />
        </div>
      )}
    </Modal>
  );
}
