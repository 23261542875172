import React, { useEffect, useState } from "react";
import InputBox from "../../Components/InputBox/InputBox";
import "./Login.css";
import OTPInputBox from "../../Components/OTPInputBox/OTPInputBox";
import Button from "../../Components/Button/Button";
import { connect } from "react-redux";
import { reCaptchaVerifier } from "../../Services/authentication";
import {
  clearCredentials,
  sendOtp,
  verifyOtp,
} from "../../Redux/authentication/actions";
import { isValidObject } from "../../Services/validators";
import { Regex } from "../../Utils/constants";
import Header from "../../Components/Header/Header";
import { Route, Routes } from "react-router-dom";
import LanguageModal from "../../Components/LanguageModal/LanguageModal";

function Login(props) {
  const [loginFormData, setLoginFormData] = useState({
    phoneNumber: "+91",
    OTP: null,
  });

  //invisible recaptcha for authentication
  useEffect(() => {
    if (!isValidObject(props.profile.data) && props.auth.data.accessToken) {
      props.navigate("/register");
    }
    // eslint-disable-next-line
  }, [props.profile.data, props.auth.data]);

  //invisible recaptcha for authentication
  useEffect(() => {
    window.reCaptchaVerifier = reCaptchaVerifier();
  }, []);

  const setLoginOTPValue = (otp) => {
    setLoginFormData({
      ...loginFormData,
      OTP: otp,
    });
  };

  const loginFormChange = (event) => {
    //To clear auth verification while user trying to change phone number after otp sent
    if (
      event.target.name === "phoneNumber" &&
      props.auth.credentials.verificationId &&
      loginFormData.phoneNumber !== event.target.value
    ) {
      props.clearCredentials();
    }
    if (event.target.name.includes("otp")) {
      return;
    } else {
      setLoginFormData({
        ...loginFormData,
        [event.target.name]: event.target.value,
      });
    }
  };

  const isOtpButtonDisabled = () => {
    if (
      typeof loginFormData.phoneNumber === "string" &&
      loginFormData.phoneNumber.trim().length >= 13 &&
      !props.auth.loading
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <Header
        languageData={props.languageData}
        languageOnClick={() => {
          props.navigate("/login/languages");
        }}
      />
      <div className="remaining-body-height inherit-parent-width display-flex flex-justify-content-center flex-direction-column">
        <div className="inherit-parent-height" data-cy="login-screen">
          <div className="flex-justify-content-center inherit-parent-height flex-align-items-center flex-direction-column ">
            <div className=" height-64px" />
            <div className=" padding-horizontal-larger">
              <div className=" padding-top-default text-align-center font-family-RHD-medium font-size-large">
                {props.languageData?.welcome}
              </div>
              <div className=" padding-top-medium text-align-center font-size-small">
                {props.languageData?.loginDescription}
              </div>
            </div>
            <form
              className="inherit-parent-height inherit-parent-width max-width-500px flex-justify-content-space-between flex-direction-column "
              onChange={(event) => {
                loginFormChange(event);
              }}
              onSubmit={(event) => event.preventDefault()}>
              <div className=" background-white padding-horizontal-larger">
                <div className="height-64px" />

                <InputBox
                  className="inherit-parent-width border-radius-default margin-bottom-large"
                  name="phoneNumber"
                  label={props.languageData?.phoneNumber}
                  type="number"
                  autoFocus={true}
                  autoComplete="off"
                  value={loginFormData.phoneNumber}
                  buttonTileId="login-button"
                  buttonTile={
                    props.auth.credentials.verificationId !== null
                      ? props.languageData?.resendOTP
                      : props.languageData?.getOTP
                  }
                  buttonType={
                    typeof props.auth.credentials.verificationId === "string"
                      ? "button"
                      : "submit"
                  }
                  onButtonClick={(event) => {
                    event.preventDefault();
                    props.sendOtp(loginFormData.phoneNumber, "login");
                  }}
                  data-cy="login-screen-phone-number-field"
                  required
                  suffixButtonDisabled={isOtpButtonDisabled()}
                  validation={(value) => {
                    if (
                      !(
                        Regex.phoneNumber.test(value.split("+91")[1]) ||
                        value === "+91"
                      )
                    ) {
                      return {
                        status: false,
                        message: props.languageData.enterValidPhoneNumber,
                      };
                    }
                  }}
                />
                {props.auth.credentials.verificationId && (
                  <OTPInputBox
                    name="otp"
                    label={props.languageData?.pleaseEnterOTP}
                    setValue={setLoginOTPValue}
                    className="margin-bottom-large"
                    required
                  />
                )}
              </div>
              <footer className="padding-larger inherit-parent-width max-width-500px ">
                <Button
                  data-cy={
                    props.auth.loading ? "login-loading-button" : "login-button"
                  }
                  loading={props.auth.loading}
                  type={
                    typeof props.auth.credentials.verificationId === "string"
                      ? "submit"
                      : "button"
                  }
                  className=""
                  text={props.languageData?.login}
                  onClick={(event) => {
                    event.preventDefault();
                    props.verifyOtp(loginFormData.OTP);
                  }}
                  disabled={
                    !(
                      typeof loginFormData.OTP === "string" &&
                      loginFormData.OTP.length === 6
                    )
                  }
                  boxShadow={false}
                />
              </footer>
            </form>
          </div>
        </div>
        <Routes>
          <Route
            path="languages"
            element={
              <LanguageModal
                languageData={props.languageData}
                languageList={props.languageList}
                navigate={props.navigate}
                changeLanguage={props.changeLanguage}
                profile={props.profile}
              />
            }
          />
        </Routes>
        <div id="recaptcha-placeholder"></div>
      </div>
    </>
  );
}

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    profile: state.profile,
  };
};

const mapDispatchToProps = function () {
  return {
    verifyOtp: (loginOtp) => verifyOtp(loginOtp),
    sendOtp: (phoneNumber, type) => sendOtp(phoneNumber, type),
    clearCredentials: () => clearCredentials(),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
