import store from "../store";
import { actionTypes } from "./saga";


export function setJobsData(data) {
  store.dispatch({
    type: actionTypes.PUT_JOBS_DATA,
    payload: {
      data: data,
    },
  });
}

export function getJobAssets(data) {
  store.dispatch({
    type: actionTypes.GET_JOB_ASSETS,
    payload: {
      data: data,
    },
  });
}
