import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Header from "../../Components/Header/Header";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import defaultProfilePic from "../../Assets/defaultUserIcon.svg";
import "./SelectedAnnouncement.css";
import { dateAndTimeConverter } from "../../Utils/constants";

function SelectedAnnouncement(props) {
  const { announcementId } = useParams("announcementId");
  const [announcementData, setAnnouncementData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [contents, setContents] = useState([]);

  useEffect(() => {
    if (props.announcements.data[announcementId]) {
      setAnnouncementData(props.announcements.data?.[announcementId]);
    } else {
      setAnnouncementData(null);
    }
  }, [props.announcements.data, announcementId]);

  async function urlData(fileURL) {
    setLoading(true);
    const response = await fetch(fileURL, {
      method: "GET",
    });
    const text = await response.text();
    if (typeof text === "string") {
      setContents(text && text);
    }
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    if (announcementData?.contents !== "") {
      const fileURL =
        announcementData?.contentsURL?.[props.profile.language].url;
      if (fileURL) {
        urlData(fileURL);
      }
      setLoading(false);
    }
  }, [announcementData, props.profile.language]);

  return (
    <>
      <Header
        languageData={props.languageData}
        title={props.languageData.viewAnnouncement}
        backOnClick={() => {
          props.navigate("/");
        }}
      />
      <div className="remaining-body-height flex-justify-content-center padding-larger font-family-RHD-medium overflow-scroll background-white">
        <div className="margin-top-200px max-width-500px inherit-parent-width">
          {announcementData === null ||
          contents === undefined ||
          announcementData?.contentsURL?.[props.profile.language]?.url ===
            undefined ||
          loading === true ? (
            <SelectedBlogShimmer />
          ) : (
            <>
              <div
                className="font-color-blue adaptive-font-size font-family-RHD-light"
                data-cy="selected-blog-tittle"
              >
                {announcementData?.title[props.profile.language]}
              </div>
              <div className="flex-direction-column padding-top-larger margin-bottom-40px overflow-scroll">
                <InfoField
                  data={announcementData}
                  language={props.profile.language}
                />
              </div>
              <div>
                <div
                  className={`${
                    props.windowInnerWidth > 900
                      ? "height-500px"
                      : "height-300px"
                  } inherit-parent-width margin-bottom-40px border-radius-default`}
                >
                  <img
                    className="inherit-parent-height inherit-parent-width object-fit-cover border-radius-default"
                    src={announcementData?.postThumbnailURL?.url || null}
                    alt={announcementData?.title[props.profile.language]}
                  />
                </div>
                <div className="display-flex inherit-parent-height inherit-parent-width flex-justify-content-space-evenly">
                  <div className="web-width-60-percent mob-width-selectedBlog">
                    <ReactMarkdown
                      remarkPlugins={[remarkGfm]}
                      className="padding-top-larger font-size-default reactMarkDown web-font-size-larger blog-line-height"
                    >
                      {contents && contents}
                    </ReactMarkdown>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    profile: state.profile,
    announcements: state.announcements,
  };
};

const mapDispatchToProps = function () {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectedAnnouncement);

const SelectedBlogShimmer = () => {
  return (
    <>
      <div className="shimmer inherit-parent-width margin-top-larger margin-bottom-large padding-larger border-radius-default" />
      <div
        style={{
          height: "343px",
        }}
        className="shimmer inherit-parent-width  margin-bottom-40px border-radius-default"
      />
      {[...Array(20).keys()].map((index) => (
        <div
          key={index}
          className="shimmer inherit-parent-width margin-top-large padding-default border-radius-default"
        />
      ))}
    </>
  );
};

const InfoField = (props) => {
  return (
    <div className="display-flex padding-bottom-large flex-center-children-vertically">
      <div className="flex-center-children-vertically">
        <img
          className="border-radius-100-percentage"
          style={{
            height: "32px",
            width: "32px",
          }}
          src={props.data?.authorProfilePic || defaultProfilePic}
          alt="profilePic:ninto"
        />
        <div className="font-family-RHD-medium padding-left-default">
          {props.data?.authorName[props.language]}
          <span className="dot-4px margin-small" />
          {dateAndTimeConverter(props.data?.createdAt, "secondDate")}
          <span className="dot-4px  margin-small" />
          {props.data?.category[props.language]}
          <span className="dot-4px  margin-small" />
          {props.data?.readTime[props.language]}
        </div>
      </div>
    </div>
  );
};
