import { all, put, takeEvery } from "redux-saga/effects";
import { setErrorStatus } from "../status/action";
import { getFileUrl } from "../../Services/storage";
import { getJobAssets } from "./action";
// import store from "../store";

export const actionTypes = {
  PUT_JOBS_DATA: "PUT_JOBS_DATA",
  GET_JOB_ASSETS: "GET_JOB_ASSETS",
};

function* setJobsDataWorker(action) {
  try {
    yield setJobsLoading(true);

    yield put({
      type: "SET_JOBS_DATA",
      payload: {
        data: action.payload.data,
      },
    });

    yield setJobsLoading(false);

    Object.values(action.payload.data).forEach((data)=>{
      getJobAssets(data)
    })

  } catch (error) {
    yield setJobsLoading(false);
    setErrorStatus(error);
  }
}

function* getJobAssetsWorker(action) {
  try {
    if (action.payload.data.company.logo) {
      yield put({
        type: "UPDATE_JOBS_DATA",
        payload: {
          [action.payload.data.documentId]: {
            ...action.payload.data,
            company: {
              ...action.payload.data.company,
              logoSrc: yield getFileUrl(action.payload.data.company.logo),
            },
          },
        },
      });
    }
  } catch (error) {
    yield setJobsLoading(false);
    setErrorStatus(error);
  }
}

export default function* jobsWatcher() {
  yield all([
    takeEvery("PUT_JOBS_DATA", setJobsDataWorker),
    takeEvery("GET_JOB_ASSETS", getJobAssetsWorker),
  ]);
}

function* setJobsLoading(bool) {
  yield put({
    type: "SET_JOBS_LOADING",
    payload: {
      loading: bool,
    },
  });
}
