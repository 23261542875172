const announcementReducer = (
  state = {
    loading: false,
    data: null,
  },
  action
) => {
  switch (action.type) {
    case "SET_ANNOUNCEMENTS_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };

    case "SET_ANNOUNCEMENTS_DATA":
      return {
        ...state,
        data: action.payload.data,
      };

    case "UPDATE_ANNOUNCEMENT_ASSETS":
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.announcementId]: action.payload.data,
        },
      };

    case "RESET":
      return {
        loading: false,
        data: null,
      };

    default:
      return state;
  }
};
export default announcementReducer;
