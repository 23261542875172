import React from "react";
import Modal from "../Modal/Modal";
import { ArrowIcon, TickIconWithCircle } from "../../Assets/assets";

export default function LanguageModal(props) {
  return (
    <Modal
      show={true}
      canIgnore={true}
      onClose={() => {
        props.navigate(-1);
      }}
      className="display-flex flex-justify-content-center"
      position=" absolute-center-self-horizontally bottom-0 "
      boxShadow="none"
      borderRadius="none"
      maxWidth="max-width-767px"
      width="inherit-parent-width"
      background="background-transparent"
      height="height-15-percentage"
    >
      <div
        data-cy="language-modal"
        className="inherit-parent-width inherit-parent-height padding-large background-white  "
      >
        <div className="text-align-center">
          {props.languageData?.chooseLanguage}
        </div>
        <div className="padding-top-large">
          {props.languageList?.map((data, index) => (
            <div
              key={index}
              data-cy={`${data.title}-card`}
              onClick={() => {
                if (props.profile.language !== data.value) {
                  props.changeLanguage(data.value);
                }
                props.navigate(-1);
              }}
              className="padding-top-default padding-bottom-large font-size-default display-flex flex-justify-content-space-between"
            >
              <div>{data.title}</div>
              {props.profile.language === data.value ? (
                <div>
                  <TickIconWithCircle />
                </div>
              ) : (
                <div>
                  <ArrowIcon />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
}
