import { put, takeEvery, all } from "redux-saga/effects";
import {
  otpValidate,
  logout,
  otpRequest,
  signInWithAuthCredentials,
} from "../../Services/authentication";
import { throwError } from "../../Services/error";
import { setErrorStatus } from "../status/action";
import store from "../store";
import { clearRegisterCredentials, login, setReferenceId } from "./actions";
import { aadhaarAuthentication } from "../../Services/api";

export const authActionTypes = {
  SEND_OTP: "SEND_OTP",
  VERIFY_OTP: "VERIFY_OTP",
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  ADD_ACCESS_TOKEN: "ADD_ACCESS_TOKEN",
  ADD_AUTH_INFO: "ADD_AUTH_INFO",
  CLEAR_CREDENTIAL: "CLEAR_CREDENTIAL",
  SEND_AADHAAR_OTP: "SEND_AADHAAR_OTP",
  VERIFY_AADHAAR_OTP: "VERIFY_AADHAAR_OTP",
  CLEAR_REGISTER_CREDENTIALS: "CLEAR_REGISTER_CREDENTIALS",
  PUT_REFERENCE_ID: "PUT_REFERENCE_ID",
};

function* loginWorker() {
  try {
    yield setAuthLoading(true);
    const uid = store.getState().auth.data.uid;
    const profile = store.getState().profile.data;
    if (uid !== null && profile !== null) {
      throw throwError("custom", "User already logged in");
    }
    yield signInWithAuthCredentials(
      store.getState().auth.credentials.authCredential
    );
    yield setAuthLoading(false);
  } catch (error) {
    yield setErrorStatus(error);
    yield setAuthLoading(false);
  }
}

function* logoutWorker() {
  try {
    yield setAuthLoading(true);
    yield logout();
    yield put({
      type: "RESET",
    });
  } catch (error) {
    yield setAuthLoading(false);
    yield setErrorStatus(error);
    yield setAuthLoading(false);
  }
}

function* sendOtpWorker(action) {
  try {
    yield setAuthLoading(true);

    const verificationId = yield otpRequest(action.payload.phoneNumber);

    yield put({
      type: "SET_AUTH_VERIFICATION_ID",
      payload: {
        verificationId: verificationId,
      },
    });

    yield setAuthLoading(false);
  } catch (error) {
    yield setErrorStatus(error);
    yield setAuthLoading(false);
  }
}

function* verifyOtpWorker(action) {
  try {
    yield setAuthLoading(true);
    const authCredential = yield otpValidate(
      action.payload.loginOtp,
      store.getState().auth.credentials.verificationId
    );

    yield put({
      type: "SET_AUTH_CREDENTIALS",
      payload: {
        authCredential: authCredential,
      },
    });
    login();
    yield setAuthLoading(false);
  } catch (error) {
    yield setErrorStatus(error);
    yield setAuthLoading(false);
  }
}

function* clearCredentialWorker() {
  yield put({
    type: "CLEAR_AUTH_CREDENTIALS",
  });
}

function* addAccessTokenWorker(action) {
  try {
    yield put({
      type: "SET_ACCESS_TOKEN",
      payload: {
        accessToken: action.payload.accessToken,
      },
    });
  } catch (error) {
    yield setErrorStatus(error);
  }
}

function* addAuthInfoWorker(action) {
  try {
    yield put({
      type: "SET_AUTH_INFO",
      payload: {
        accessToken: action.payload.accessToken,
        uid: action.payload.uid,
        phoneNumber: action.payload.phoneNumber,
      },
    });
  } catch (error) {
    yield setErrorStatus(error);
  }
}

function* sendAadharOtpWorker(action) {
  try {
    yield setAuthLoading(true);

    const body = {
      aadhaarNumber: action.payload.aadhaarNumber,
    };

    const response = yield aadhaarAuthentication(
      body,
      store.getState().auth.data.accessToken,
      "sendOtp"
    );

    yield put({
      type: "SET_AADHAAR_TRANSACTION_ID",
      payload: {
        aadhaarNumber: action.payload.aadhaarNumber,
        transactionId: response.data.client_id,
      },
    });

    yield setAuthLoading(false);
  } catch (error) {
    yield setAuthLoading(false);

    if (error.message === "Already registered") {
      setErrorStatus({
        code: "custom",
        message: {
          english: "Aadhaar number already registered",
          tamil: "ஆதார் எண் ஏற்கனவே பதிவு செய்யப்பட்டுள்ளது",
        },
      });
    } else {
      yield setErrorStatus(error);
    }
  }
}

function* verifyAadharOtpWorker(action) {
  try {
    yield setAuthLoading(true);

    const body = {
      phoneNumber: store.getState().auth.data.phoneNumber,
      transactionId: store.getState().auth.register.transactionId,
      otp: action.payload.otp,
      ...(store.getState().auth.register.refId
        ? { refId: store.getState().auth.register.refId }
        : {}),
    };

    yield aadhaarAuthentication(
      body,
      store.getState().auth.data.accessToken,
      "verifyOtp"
    );

    setReferenceId(null);

    yield setAuthLoading(false);
  } catch (error) {
    yield setAuthLoading(false);
    console.error("error", error);

    if (error.message === "Verification Failed.") {
      setErrorStatus({ code: "auth/invalid-credential" });
    } else if (error.message === "OTP timed out.") {
      setErrorStatus({
        code: "custom",
        message: {
          english: "OTP timed out.",
          tamil: "OTP நேரம் முடிந்தது.",
        },
      });
      clearRegisterCredentials();
    } else {
      setErrorStatus(error);
      clearRegisterCredentials();
    }
  }
}

function* clearRegisterCredentialsWorker() {
  try {
    yield put({
      type: "CLEAR_AADHAAR_TRANSACTION_ID",
    });
  } catch (error) {
    yield setErrorStatus(error);
  }
}

function* putReferenceIdWorker(action) {
  try {
    yield put({
      type: "SET_REFERENCE_ID",
      payload: {
        refId: action.payload.refId,
      },
    });
  } catch (error) {
    yield setErrorStatus(error);
  }
}

export default function* authWatcher() {
  yield all([
    takeEvery("SEND_OTP", sendOtpWorker),
    takeEvery("VERIFY_OTP", verifyOtpWorker),
    takeEvery("LOGIN", loginWorker),
    takeEvery("LOGOUT", logoutWorker),
    takeEvery("ADD_ACCESS_TOKEN", addAccessTokenWorker),
    takeEvery("ADD_AUTH_INFO", addAuthInfoWorker),
    takeEvery("CLEAR_CREDENTIAL", clearCredentialWorker),
    takeEvery("SEND_AADHAAR_OTP", sendAadharOtpWorker),
    takeEvery("VERIFY_AADHAAR_OTP", verifyAadharOtpWorker),
    takeEvery("CLEAR_REGISTER_CREDENTIALS", clearRegisterCredentialsWorker),
    takeEvery("PUT_REFERENCE_ID", putReferenceIdWorker),
  ]);
}

function* setAuthLoading(bool) {
  yield put({
    type: "SET_AUTH_LOADING",
    payload: {
      loading: bool,
    },
  });
}
