const appReducer = (
  state = {
    loading: false,
    data: null,
  },
  action
) => {
  switch (action.type) {
    case "SET_APP_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };

    case "SET_PERMISSIONS":
      return {
        ...state,
        data: { ...state.data, permissions: action.payload },
      };

    case "SET_LOCATION_DATA":
      return {
        ...state,
        data: { ...state.data, locations: action.payload },
      };

    case "SET_REG_DATA":
      return {
        ...state,
        data: { ...state.data, regData: action.payload },
      };

    case "RESET_APP_DATA":
      return {
        loading: false,
        data: null,
      };

    default:
      return state;
  }
};
export default appReducer;
