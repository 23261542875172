import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "./Privacy.css";
import { Route, Routes } from "react-router-dom";
import LanguageModal from "../../Components/LanguageModal/LanguageModal";

export default function Privacy(props) {
  const [loading, setLoading] = useState(false);
  const [contents, setContents] = useState([]);

  async function urlData(fileURL) {
    setLoading(true);
    const response = await fetch(fileURL, {
      method: "GET",
    });
    const text = await response.text();
    if (typeof text === "string") {
      setContents(text && text);
    }
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    const fileURL = `${process.env.PUBLIC_URL}/Assets/${
      props.profile.language === "tamil"
        ? "privacy-Tamil.md"
        : "privacy-English.md"
    }`;
    if (fileURL) {
      urlData(fileURL);
    }
    setLoading(false);
  }, [props.profile.language]);

  return (
    <>
      <Header
        languageData={props.languageData}
        title={props.languageData.privacyPolicy}
        backOnClick={() => {
          props.navigate("/");
        }}
        languageOnClick={() => {
          props.navigate("/privacy/languages");
        }}
      />
      <div className="remaining-body-height inherit-parent-width display-flex flex-justify-content-center flex-direction-column padding-large overflow-scroll">
        {loading ? (
          <div className=" flex-align-items-center flex-justify-content-center inherit-parent-height inherit-parent-width">
            <div className="qr-loader" data-cy="qr-loader" />
          </div>
        ) : (
          <div className="display-flex inherit-parent-height inherit-parent-width flex-justify-content-space-evenly">
            <div className="web-width-60-percent mob-width-selectedBlog">
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                className="font-size-default reactMarkDown web-font-size-larger blog-line-height"
              >
                {contents}
              </ReactMarkdown>
            </div>
          </div>
        )}
      </div>
      <Routes>
        <Route
          path="languages"
          element={
            <LanguageModal
              languageData={props.languageData}
              languageList={props.languageList}
              navigate={props.navigate}
              changeLanguage={props.changeLanguage}
              profile={props.profile}
            />
          }
        />
      </Routes>
    </>
  );
}
