import { all, put, takeEvery } from "redux-saga/effects";
import { setErrorStatus } from "../status/action";
import store from "../store";
import { isValidArray, isValidObject } from "../../Services/validators";
import { getFileUrl } from "../../Services/storage";
import { getAnnouncementAssets } from "./action";
// import { raisePetition } from "../../Services/api";

export const actionTypes = {
  PUT_ANNOUNCEMENTS_DATA: "PUT_ANNOUNCEMENTS_DATA",
  CREATE_ANNOUNCEMENTS: "CREATE_ANNOUNCEMENTS",
  GET_ANNOUNCEMENT_ASSETS: "GET_ANNOUNCEMENT_ASSETS",
  SET_NEW_COMPLAINT_DATA: "SET_NEW_COMPLAINT_DATA",
};

function* setAnnouncementsWorker(action) {
  try {
    yield setAnnouncementsLoading(true);
    yield put({
      type: "SET_ANNOUNCEMENTS_DATA",
      payload: {
        data: action.payload.data,
      },
    });

    if (isValidObject(action.payload.data)) {
      const arrayOfData = Object.values(action.payload.data);
      for (let i = 0; i < arrayOfData.length; i++) {
        getAnnouncementAssets(arrayOfData?.[i]);
      }
    }

    yield setAnnouncementsLoading(false);
  } catch (error) {
    yield setAnnouncementsLoading(false);
    setErrorStatus(error);
  }
}

function* createAnnouncementsWorker(action) {
  try {
    yield setAnnouncementsLoading(true);
    const { proof, summary } = action.payload;

    let data = new FormData();
    const fileData = [...proof.image, ...proof.pdf];
    if (isValidArray(fileData)) {
      for (let i = 0; i < fileData.length; i++) {
        data.append("files", fileData[i]);
      }
    }
    data.append("description", summary);
    data.append(
      "issuedBy",
      JSON.stringify({
        issuedAt: +new Date(),
        userDetails: {
          name: store.getState().profile.data.aadhaar.fullName,
          phoneNumber: store.getState().auth.data.phoneNumber,
        },
      })
    );
    data.append(
      "status",
      JSON.stringify({
        currentStatus: "OPEN",
        updatedAt: +new Date(),
      })
    );

    yield setAnnouncementsLoading(false);
  } catch (error) {
    yield setAnnouncementsLoading(false);
    setErrorStatus(error);
  }
}

function* getAnnouncementAssetsWorker(action) {
  try {
    const data = action.payload.data;

    const postThumbnailURL = yield getFileUrl(data.postThumbnail);
    const contentsURL = {
      english: yield getFileUrl(data.contents?.["english"]),
      tamil: yield getFileUrl(data.contents?.["tamil"]),
    };
    const announcement = {
      ...data,
      postThumbnailURL: postThumbnailURL,
      contentsURL: contentsURL,
    };

    yield put({
      type: "UPDATE_ANNOUNCEMENT_ASSETS",
      payload: {
        announcementId: data.documentId,
        data: announcement,
      },
    });
  } catch (error) {
    setErrorStatus(error);
  }
}

export default function* announcementsWatcher() {
  yield all([
    takeEvery("PUT_ANNOUNCEMENTS_DATA", setAnnouncementsWorker),
    takeEvery("CREATE_ANNOUNCEMENTS", createAnnouncementsWorker),
    takeEvery("GET_ANNOUNCEMENT_ASSETS", getAnnouncementAssetsWorker),
  ]);
}

function* setAnnouncementsLoading(bool) {
  yield put({
    type: "SET_ANNOUNCEMENTS_LOADING",
    payload: {
      loading: bool,
    },
  });
}
