import store from "../store";
import { statusActionTypes } from "./saga";


export function setDisplayScreen(screen) {
  store.dispatch({
    type: statusActionTypes.PUT_DISPLAY_SCREEN,
    payload: {
      screen : screen
    },
  });
}

