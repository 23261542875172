import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import TextArea from "../../Components/TextArea/TextArea";
import { AddIcon, CloseIcon, InfoIcon, PdfFileIcon } from "../../Assets/assets";
import "./CreatePetition.css";
import { setErrorStatus } from "../../Redux/status/action";
import Modal from "../../Components/Modal/Modal";
import { isValidArray } from "../../Services/validators";
import CropperModal from "../../Components/CropperModal/CropperModal";
import {
  createPetitions,
  setNewComplaintData,
} from "../../Redux/petitions/action";
import Header from "../../Components/Header/Header";
import ConditionalRender from "../../Components/ConditionalRender/ConditionalRender";
import SuccessScreen from "../../Components/SuccessScreen/SuccessScreen";
import { minWordCount, summaryWordCount } from "../../Utils/constants";
import Button from "../../Components/Button/Button";
import AllowLocationFallBack from "../../Assets/Location_permission.svg";

function CreatePetition(props) {
  const [formData, setFormData] = useState({
    summary: "",
  });

  const [file, setFile] = useState({
    image: [],
    pdf: [],
  });

  const [permissionData, setPermissionData] = useState(null);

  useEffect(() => {
    setPermissionData(props.app.data?.permissions);
    // eslint-disable-next-line
  }, [props.app.data]);

  const [croppedImage, setCroppedImage] = useState([]);

  const values = {
    Location: {
      dataCy: "location-permission-screen",
      title: props.languageData.allowLocationPermission,
      description: props.languageData.allowLocationPermissionDescription,
      images: AllowLocationFallBack,
      onclick: () => {
        window.location.href =
          "origin25://app-permission?location-permission=true";
      },
    },
  };

  useEffect(() => {
    props.setNewComplaintData(null);
    // eslint-disable-next-line
  }, []);

  const showPermissionStep = (data) => {
    if (!data?.location) {
      return "Location";
    }
  };

  return (
    <>
      <Header
        languageData={props.languageData}
        title={props.languageData?.newPetition}
        backOnClick={() => {
          props.navigate("/");
          props.setNewComplaintData(null);
        }}
      />
      <div className="remaining-body-height inherit-parent-width flex-justify-content-center">
        <div className=" inherit-parent-height max-width-500px">
          <ConditionalRender condition={!props.app.data.permissions.location}>
            <div className="padding-larger display-flex flex-justify-content-center inherit-parent-width inherit-parent-height flex-align-items-center flex-direction-column ">
              {Object.values(values).map(
                (data, index) =>
                  Object.keys(values)?.[index] ===
                    showPermissionStep(permissionData) && (
                    <div
                      data-cy={data.dataCy}
                      key={index}
                      className="display-flex flex-direction-column flex-justify-content-space-between inherit-parent-height"
                    >
                      <div />

                      <div className="display-flex flex-justify-content-center flex-direction-column">
                        <img
                          style={
                            Object.keys(values)?.[index] ===
                            "DisableBatteryOptimization"
                              ? { height: "376px", width: "260px" }
                              : {}
                          }
                          draggable={false}
                          src={data.images}
                          alt="any"
                        />

                        <div className="text-align-center padding-top-larger padding-bottom-large font-size-default font-family-RHD-medium">
                          {data.title}
                        </div>

                        <div className="text-align-center padding-bottom-large font-size-medium">
                          {data.description}
                        </div>
                      </div>

                      <Button
                        className="padding-default"
                        onClick={data.onclick}
                        text={convertName(Object.keys(values)?.[index])}
                      />
                    </div>
                  )
              )}
            </div>
          </ConditionalRender>

          <ConditionalRender
            condition={
              !props.petitions?.isPetitionSubmitted &&
              props.app.data.permissions.location
            }
          >
            {props.petitions.loading ? (
              <div className="padding-left-default inherit-parent-height display-flex flex-align-items-center flex-justify-content-center padding-right-default padding-top-small padding-bottom-small border-radius-default ">
                <div className="qr-loader" data-cy="qr-loader" />
              </div>
            ) : (
              <form
                className="padding-large inherit-parent-height flex-justify-content-space-between flex-direction-column"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    [e.target.name]: e.target.value,
                  });
                }}
                onSubmit={(e) => {
                  e.preventDefault();
                  props.createPetitions(formData?.summary, {
                    image: croppedImage,
                    pdf: file.pdf,
                  });

                  setFormData({
                    summary: "",
                  });

                  setFile({
                    image: [],
                    pdf: [],
                  });

                  setCroppedImage([]);
                }}
              >
                <div className="">
                  <TextArea
                    className="inherit-parent-width border-radius-default padding-top-large"
                    type="text"
                    autoFocus={true}
                    wordCount={summaryWordCount}
                    minWordCount={minWordCount}
                    data-cy="summary-input-field"
                    name="summary"
                    label={props.languageData.summary}
                    removeResponsive={true}
                    value={formData.summary}
                  />
                  <div className="padding-top-large font-size-smaller font-family-RHD-medium">
                    {props.languageData.addDocuments.toUpperCase()} &nbsp; (
                    {[...croppedImage, ...file.pdf].length || 0} / 4)
                  </div>
                  <div className="padding-top-large height-fit-content">
                    <UploadFile
                      setFile={setFile}
                      file={file}
                      tempSrc={croppedImage}
                      RemoveImageOnClick={(data, type) => {
                        if (type === "image") {
                          setCroppedImage(
                            Object.values(croppedImage).filter(
                              (value) => value.name !== data
                            )
                          );
                        } else {
                          setFile({
                            ...file,
                            pdf: file.pdf.filter(
                              (_value, index) => index !== data
                            ),
                          });
                        }
                      }}
                    />
                  </div>
                  <div className="display-flex flex-align-items-center padding-top-medium">
                    <div style={{ width: "16px", height: "16px" }}>
                      <InfoIcon color="#404040" />
                    </div>
                    <div className="padding-left-default font-size-small">
                      {props.languageData.supportedFormatDescription}
                    </div>
                  </div>
                </div>
                <div className="inherit-parent-width">
                  <Button
                    data-cy="submit-button"
                    loading={props.petitions.loading}
                    type="submit"
                    text={props.languageData.submit.toUpperCase()}
                    boxShadow={false}
                    disabled={
                      (formData.summary.trim() &&
                        formData.summary.trim().length > minWordCount &&
                        formData.summary.trim().length <= summaryWordCount) ||
                      croppedImage.length > 0 ||
                      file.pdf.length > 0
                        ? false
                        : true
                    }
                  />
                </div>
              </form>
            )}
          </ConditionalRender>

          <ConditionalRender
            condition={
              props.petitions?.isPetitionSubmitted &&
              props.app.data.permissions.location
            }
          >
            <SuccessScreen
              continueOnClick={() => {
                props.navigate("/");
                props.setNewComplaintData(null);
              }}
              languageData={props.languageData}
              text={
                <>
                  {props.languageData.petitionSubmitted}
                  <div className=" font-size-default padding-top-default">
                    {props.languageData.petitionId}:{" "}
                    {props.petitions.petitionId}
                  </div>
                </>
              }
              buttonText={props.languageData?.okay}
            />
          </ConditionalRender>

          <Modal
            show={isValidArray(file?.image)}
            canIgnore={true}
            onClose={() => {
              setFile({ ...file, image: [] });
            }}
            width="inherit-parent-width"
            maxWidth="max-width-800px"
            background="false"
            boxShadow="false"
            borderRadius="false"
            height="inherit-parent-height max-height-500px"
          >
            <div
              data-cy="cropper-modal"
              className={`background-white inherit-parent-height border-radius-default box-shadow-default font-family-gilroy-regular font-color-secondary`}
            >
              <CropperModal
                className="border-radius-default"
                OnBlobCreate={(blob) => {
                  const imageFile = new File([blob], `${+new Date()}.png`, {
                    type: "image/png",
                  });
                  setCroppedImage([...croppedImage, imageFile]);
                }}
                file={file.image}
                setFile={() => {
                  setFile({ ...file, image: [] });
                }}
              />
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    app: state.app,
    petitions: state.petitions,
  };
};

const mapDispatchToProps = function () {
  return {
    createPetitions: (data, files) => createPetitions(data, files),
    setNewComplaintData: (status) => setNewComplaintData(status),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePetition);

const UploadFile = (props) => {
  return (
    <div
      style={{
        width: "100%",
        overflowY: "scroll",
        height: "56px",
      }}
      data-cy="upload-input-field"
      className="display-flex hide-scrollbar flex-align-items-center"
    >
      {isValidArray(props.tempSrc) &&
        props.tempSrc?.map((data, index) => (
          <div
            key={index}
            style={{ height: "56px" }}
            className="margin-right-large position-relative"
            data-cy={`uploaded-image-thumbnail-${index}`}
          >
            <div
              onClick={() => {
                props.RemoveImageOnClick(data.name, "image");
              }}
              data-cy="remove-uploaded-image-button"
              className="background-color-grey width-fit-content border-radius-100-percentage flex-align-items-center flex-justify-content-center position-absolute right-0 opacity-60-percent"
            >
              <CloseIcon />
            </div>
            <img
              className="border-radius-default"
              height={56}
              width={56}
              src={data && URL.createObjectURL(data)}
              alt="data"
            />
          </div>
        ))}
      {isValidArray(props.file.pdf) &&
        props.file.pdf?.map((data, index) => (
          <div
            key={index}
            style={{ height: "56px" }}
            className="margin-right-large position-relative"
            data-cy={`uploaded-pdf-thumbnail`}
          >
            <div
              onClick={() => {
                props.RemoveImageOnClick(index, "pdf");
              }}
              data-cy="remove-uploaded-image-button"
              className="background-color-grey width-fit-content border-radius-100-percentage flex-align-items-center flex-justify-content-center position-absolute right-0 opacity-60-percent"
            >
              <CloseIcon />
            </div>
            <div
              style={{
                height: "56px",
                width: "56px",
                backgroundColor: "#757575",
              }}
              className="border-radius-default font-color-white flex-align-items-center flex-justify-content-center"
            >
              <PdfFileIcon height={40} width={40} />
            </div>
          </div>
        ))}
      {([...props.tempSrc, ...props.file.pdf].length === 0 ||
        [...props.tempSrc, ...props.file.pdf].length <= 3) && (
        <form
          className="inherit-parent-width"
          onChange={(event) => {
            if (event.target.files) {
              if (
                [
                  "image/png",
                  "image/jpeg",
                  "image/jpg",
                  "application/pdf",
                ].includes(event.target.files[0]?.type) === false
              ) {
                return setErrorStatus({
                  code: "input/file-invalid",
                });
              }

              if (
                typeof event.target.files[0]?.size === "number" &&
                event.target.files[0].size > 5000000
              ) {
                return setErrorStatus({
                  code: "input/file-size-exceeded",
                  message: "Document size should be less than 5MB",
                });
              }

              if (
                ["image/png", "image/jpeg", "image/jpg"].includes(
                  event.target.files[0]?.type
                ) === true
              ) {
                props.setFile({
                  ...props.file,
                  image: [
                    ...props.file.image,
                    URL.createObjectURL(event.target.files[0]),
                  ],
                });
              } else if (
                ["application/pdf"].includes(event.target.files[0]?.type) ===
                true
              ) {
                props.setFile({
                  ...props.file,
                  pdf: [...props.file.pdf, event.target.files[0]],
                });
              }
            }
          }}
        >
          <label>
            <input
              type="file"
              accept=".jpg,.jpeg,.png,.pdf"
              name={props.inputName ? props.inputName : "fileUploaderInput"}
              onClick={(event) => {
                event.target.value = "";
              }}
              className="visibility-hidden position-absolute"
            />
            <div
              data-cy="upload-add-field"
              style={{ width: "56px", height: "56px" }}
              className="display-flex flex-align-items-center flex-justify-content-center black-dashed-border border-radius-default cursor-pointer"
            >
              <AddIcon color="#757575" />
            </div>
          </label>
        </form>
      )}
    </div>
  );
};

const convertName = (key) => {
  if (key === "Location") {
    return "Confirm";
  } else if (key === "Notification") {
    return "Confirm";
  } else {
    setErrorStatus({ code: "custom", message: "Invalid permission key" });
  }
};
