import React, { useEffect, useRef, useState } from "react";
import "./TextArea.css";

function TextArea(props) {
  const [rows, setRows] = useState(2);
  const label = useRef(null);
  const inputRef = useRef(null);

  // set initial set of rules
  useEffect(() => {
    if (
      inputRef.current &&
      inputRef.current.clientHeight < inputRef.current.scrollHeight
    ) {
      setRows(4);
    }
  }, [props.defaultValue]);

  const handleTextInput = (event) => {
    // setValue(event.target.value);
    typeof props.onChange === "function" && props.onChange(event);
    if (
      inputRef.current &&
      inputRef.current.clientHeight < inputRef.current.scrollHeight
    ) {
      if (rows < 4) {
        setRows((pervState) => pervState + 1);
      }
    }
  };

  return (
    <div className="inherit-parent-width display-flex flex-direction-column">
      <div
        className={`position-relative inherit-parent-width flex-align-items-end ${
          props.className ? props.className : ``
        } ${props.disabled === true ? "background-color-grey" : ""}
        `}
      >
        <div className="flex-center-children-horizontally font-size-medium font-family-RHD-medium inherit-parent-width">
          <div className="inherit-parent-width">
            <textarea
              data-cy={props["data-cy"]}
              rows={rows}
              ref={inputRef}
              defaultValue={props.defaultValue}
              value={props.value}
              className={`text-area inherit-parent-width font-color-secondary font-family-RHD-medium font-size-medium padding-top-default display-block min-width-250px text-area-border-bottom-default border-radius-0
                  ${
                    typeof props.buttonName === "string"
                      ? "padding-right-4p7-em"
                      : ""
                  } ${
                props.disabled === true ? "font-color-secondary disabled" : ""
              }
                  `}
              autoComplete={props.autoComplete}
              onFocus={() => {
                if (!props.disabled) {
                  label.current?.classList.add("active");
                  inputRef.current?.classList.add("active");
                }
              }}
              onBlur={(event) => {
                if (event.target.value.trim().length === 0) {
                  setRows(2);
                  label.current.classList.remove("active");
                }
                inputRef.current.classList.remove("active");
                if (typeof props.onOutOfFocus === "function") {
                  props.onOutOfFocus();
                }
              }}
              type={props.type}
              name={props.name}
              onChange={(e) => {
                if (props.value.length <= props.wordCount) {
                  handleTextInput(e);
                }
              }}
              autoFocus={props.autoFocus}
              readOnly={props.disabled}
              required={props.required}
            />
            <span className="text-area-border-bottom"></span>
            <label
              htmlFor={props.name}
              ref={label}
              className={`  ${
                props.removeResponsive ? "" : "adaptive-font-size"
              } font-color-secondary text-area-label position-absolute font-family-RHD-medium font-size-medium ${
                inputRef.current === document.activeElement ||
                (!!props.value
                  ? String(props.value).trim().length !== 0
                  : false) ||
                (!!props.defaultValue
                  ? String(props.defaultValue).trim().length !== 0
                  : false) ||
                props.alwaysActive
                  ? "active"
                  : ""
              } ${props.labelClassName ? props.labelClassName : ""} ${
                props.disabled === true ? "background-color-grey disabled" : ""
              }`}
              onFocus={() => {
                if (!props.disabled) {
                  label.current.classList.add("active");
                  inputRef.current.classList.add("active");
                }
              }}
            >
              {props.label}
            </label>
          </div>
        </div>
      </div>
      <InputInfo
        value={props.value}
        wordCount={props.wordCount}
        minWordCount={props.minWordCount}
      />
    </div>
  );
}

export default TextArea;

const InputInfo = (props) => {
  return (
    <div className="font-size-smaller font-family-RHD-medium padding-top-default">
      {props.value && props.value.length < props.minWordCount && (
        <div
          style={{ color: "red" }}
        >{`Min ${props.minWordCount} characters is required`}</div>
      )}
      {props.value.length >= props.minWordCount &&
        props.value.length > props.wordCount && (
          <div
            style={{ color: "red" }}
          >{`Only ${props.wordCount} characters are allowed`}</div>
        )}
      {props.value.length >= props.minWordCount &&
        props.value.length <= props.wordCount && (
          <div>
            {props.value.length || 0} / {props.wordCount}
          </div>
        )}
    </div>
  );
};
