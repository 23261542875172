const navigationReducer = (
  state = {
    screen: "home",
  },
  action
) => {
  switch (action.type) {
    case "SET_DISPLAY_SCREEN":
      return {
        screen: action.payload.screen,
      };

    case "RESET":
      return {
        screen: "home",
      };

    default:
      return state;
  }
};
export default navigationReducer;
