import store from "../store";
import { actionTypes } from "./saga";


export function updateOpting (optingStatus) {
  store.dispatch({
    type: actionTypes.UPDATE_OPTING,
    payload: {
      optingStatus: optingStatus,
    },
  });
}

export function setVolunteeringData(data) {
  store.dispatch({
    type: actionTypes.PUT_VOLUNTEERING_DATA,
    payload: {
      data: data,
    },
  });
}

export function getVolunteeringAssets(data) {
  store.dispatch({
    type: actionTypes.GET_VOLUNTEERING_ASSETS,
    payload: {
      data: data,
    },
  });
}


