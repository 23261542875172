import store from "../store";
import { actionTypes } from "./saga";

/**
 * @module AppSaga
 */

/**
 *
 * @param {permission} payload permission data
 */
export function setPermissionStatus(permission, navigate) {
  store.dispatch({
    type: actionTypes.SET_PERMISSION_STATUS,
    payload: {
      permission: permission,
      navigate: navigate,
    },
  });
}

/**
 *
 * @param {location} payload location data
 */
export function setLocationStatus(location, navigate) {
  store.dispatch({
    type: actionTypes.SET_LOCATION_STATUS,
    payload: {
      location: location,
      navigate: navigate,
    },
  });
}

/**
 * put REG TOKEN DATA
 * @param {object} data
 */
export function putRegistrationData(data, navigate) {
  store.dispatch({
    type: actionTypes.PUT_REGISTRATION_DATA,
    payload: {
      data: data,
      navigate: navigate,
    },
  });
}

/**
 * SAVE REG TOKEN AND DEVICE INFO DATA
 */
export function addRegistrationData(data) {
  store.dispatch({
    type: actionTypes.ADD_REGISTRATION_DATA,
    payload: { data: data },
  });
}

/**
 * UPDATE REG TOKEN AND DEVICE INFO DATA
 */
export function updateRegistrationData(data) {
  store.dispatch({
    type: actionTypes.UPDATE_REGISTRATION_DATA,
    payload: { data: data },
  });
}

/**
 *
 */
export function clearPermissions() {
  store.dispatch({
    type: actionTypes.CLEAR_PERMISSION_STATUS,
  });
}
