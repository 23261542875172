/**
 * @module AuthSaga
 */

import store from "../store";
import { authActionTypes } from "./saga";

/**
 * Sends OTP to the Phone number in payload
 * @param {string} phoneNumber
 */
export function sendOtp(phoneNumber) {
  store.dispatch({
    type: authActionTypes.SEND_OTP,
    payload: {
      phoneNumber: phoneNumber,
    },
  });
}

/**
 * Verifies OTP from the Phone number in payload
 * @param {string} loginOtp
 */
export function verifyOtp(loginOtp) {
  store.dispatch({
    type: authActionTypes.VERIFY_OTP,
    payload: {
      loginOtp: loginOtp,
    },
  });
}

/**
 * Login in using OTP from payload
 * @param {string} loginOtp
 */
export function login(loginOtp) {
  store.dispatch({
    type: authActionTypes.LOGIN,
    payload: {
      loginOtp: loginOtp,
    },
  });
}

/**
 * Clears the credentials after login
 */
export function clearCredentials() {
  store.dispatch({
    type: authActionTypes.CLEAR_CREDENTIAL,
  });
}

/**
 * For logging out of user
 */
export function logout() {
  store.dispatch({
    type: authActionTypes.LOGOUT,
  });
}

/**
 * Update Access token
 * @param {string} accessToken
 */
export function addAccessToken(accessToken) {
  store.dispatch({
    type: authActionTypes.ADD_ACCESS_TOKEN,
    payload: {
      accessToken: accessToken,
    },
  });
}

/**
 * Update Auth Info
 * @param {string} accessToken
 * @param {string} uid
 * @param {string} phoneNumber
 */
export function addAuthInfo(data) {
  store.dispatch({
    type: authActionTypes.ADD_AUTH_INFO,
    payload: {
      accessToken: data.accessToken,
      uid: data.uid,
      phoneNumber: data.phoneNumber,
    },
  });
}

export function sendAadhaarOtp(aadhaarNumber) {
  store.dispatch({
    type: authActionTypes.SEND_AADHAAR_OTP,
    payload: {
      aadhaarNumber: aadhaarNumber,
    },
  });
}

export function verifyAadhaarOtp(otp) {
  store.dispatch({
    type: authActionTypes.VERIFY_AADHAAR_OTP,
    payload: {
      otp: otp,
    },
  });
}

export function clearRegisterCredentials() {
  store.dispatch({
    type: authActionTypes.CLEAR_REGISTER_CREDENTIALS,
  });
}

export function setReferenceId(refId) {
  store.dispatch({
    type: authActionTypes.PUT_REFERENCE_ID,
    payload: {
      refId: refId,
    },
  });
}
