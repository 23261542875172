import React from "react";
import Fallback from "../Fallback/Fallback";
import VolunteeringFallbackIcon from "../../Assets/VolunteeringFallbackIcon.svg";
import Button from "../Button/Button";
import ConditionalRender from "../ConditionalRender/ConditionalRender";
import { isValidObject } from "../../Services/validators";
import { ArrowIcon, InfoIcon, LocationIcon } from "../../Assets/assets";
import { dateAndTimeConverter } from "../../Utils/constants";
import { Route, Routes } from "react-router-dom";
import OptInModal from "../OptInModal/OptInModal";

export default function Volunteer(props) {
  return (
    <>
      <ConditionalRender
        condition={
          props.profile.data?.volunteering?.currentStatus === "OPTEDOUT"
        }>
        <div className=" inherit-parent-height flex-justify-content-center flex-direction-column">
          <Fallback
            fallbackIcon={VolunteeringFallbackIcon}
            title={props.languageData.optInVolunteerRequests}
            description={props.languageData.optInVolunteerRequestsDescription}
            dataCy="volunteer-requests-screen"
          />

          <div className="">
            <Button
              data-cy={"yes-optIn-button"}
              type={"button"}
              className=" "
              text={`${props.languageData.yes.toUpperCase()}, ${props.languageData.optIn.toUpperCase()}`}
              onClick={() => {
                props.navigate("/optIn");
              }}
              disabled={false}
              boxShadow={false}
            />
          </div>
        </div>
      </ConditionalRender>

      <ConditionalRender
        condition={
          props.profile.data?.volunteering?.currentStatus === "OPTEDIN" &&
          props.volunteer.data &&
          Object.values(props.volunteer.data)?.length === 0
        }>
        <Fallback
          fallbackIcon={VolunteeringFallbackIcon}
          title={props.languageData.noRequestsAvailable}
          description={props.languageData.noVolunteerFallbackDescription}
          dataCy="no-volunteering-fallback"
          // showComingSoon={true}
        />
      </ConditionalRender>

      <ConditionalRender
        condition={
          props.profile.data?.volunteering?.currentStatus === "OPTEDIN" &&
          isValidObject(props.volunteer?.data)
        }>
        <div
          className=" inherit-parent-height overflow-scroll"
          data-cy={"volunteer-requests-list-screen"}>
          {props.volunteer.data &&
            Object.values(props.volunteer.data)
              .sort((a, b) => {
                return b.createdAt - a.createdAt;
              })
              .map((data, index) => (
                <React.Fragment key={index}>
                  <VolunteeringCard
                    data={data}
                    cardClick={(documentId) => {
                      props.navigate(`/volunteering/${documentId}`);
                    }}
                    language={props.profile.language}
                    languageData={props.languageData}
                  />
                </React.Fragment>
              ))}
        </div>
      </ConditionalRender>

      <Routes>
        <Route
          path="/optIn"
          element={
            <OptInModal
              languageData={props.languageData}
              navigate={props.navigate}
              updateOpting={props.updateOpting}
            />
          }
        />
      </Routes>
    </>
  );
}

const VolunteeringCard = (props) => {
  return (
    <div
      onClick={() => {
        props.cardClick(props.data?.documentId);
      }}
      style={{
        height: "102px",
      }}
      data-cy={`${props.data?.documentId}-volunteering`}
      className="inherit-parent-width display-flex margin-bottom-large gradient-border">
      <div className="height-width-100px">
        {props.data?.thumbnailSrc?.url ? (
          <img
            className="height-width-100px"
            style={{
              borderRadius: "4px 0px 0px 4px",
              paddingLeft: "2px",
              paddingTop: "2px",
              objectFit: "fill",
            }}
            src={props.data?.thumbnailSrc?.url || ""}
            alt="volunteering-thumbnail"
          />
        ) : (
          <div
            className="shimmer inherit-parent-width inherit-parent-height"
            style={{
              borderRadius: "2px",
              paddingLeft: "2px",
              objectFit: "fill",
            }}
          />
        )}
      </div>
      <div
        className=" display-flex padding-medium"
        style={{ width: "calc(100% - 100px)" }}>
        <div className="" style={{ width: "calc(100% - 16px)" }}>
          <div className=" display-flex flex-align-items-center font-family-RHD-medium font-color-quaternary">
            <div
              className=" font-size-small white-space-no-wrap text-overflow-ellipsis "
              style={{ maxWidth: "50%" }}>
              {props.data.contact.name[props.language]}
            </div>
            <div className=" dot-4px-quaternary background-color-quaternary margin-horizontal-default" />
            <div className=" font-size-small  white-space-no-wrap text-overflow-ellipsis">
              {dateAndTimeConverter(props.data.createdAt, "secondDate")}
            </div>
          </div>

          <div
            className={`${
              props.data.status.currentStatus === "CLOSED"
                ? "font-color-quaternary"
                : ""
            } white-space-no-wrap text-overflow-ellipsis font-family-RHD-medium padding-top-default `}>
            {props.data.title[props.language]}
          </div>

          <div className="white-space-no-wrap text-overflow-ellipsis font-family-RHD-medium font-size-medium display-flex flex-align-item-center padding-top-default">
            <ConditionalRender
              condition={props.data.status.currentStatus === "OPEN"}>
              <LocationIcon width="16" height="16" color="#868686" />{" "}
              <div className=" padding-left-small white-space-no-wrap text-overflow-ellipsis font-size-small font-color-quaternary">
                {" "}
                {props.data.location.address[props.language]},{" "}
                {props.data.location.city[props.language]}
              </div>
            </ConditionalRender>

            <ConditionalRender
              condition={props.data.status.currentStatus === "CLOSED"}>
              <InfoIcon color={"#868686"} />{" "}
              <div className=" padding-left-small white-space-no-wrap text-overflow-ellipsis font-size-small font-color-quaternary">
                {props.languageData.noAcceptingVolunteers}
              </div>
            </ConditionalRender>
          </div>
        </div>

        <ArrowIcon />
      </div>
    </div>
  );
};
