import noJobsFallbackIcon from "../../Assets/noJobsFallbackIcon.svg";

export default function Fallback(props) {
  return (
    <div
      className="inherit-parent-height flex-justify-content-space-between flex-direction-column "
      data-cy={`fallback-screen`}>
      <div
        className=" inherit-parent-height inherit-parent-width flex-justify-content-center flex-align-items-center flex-direction-column"
        data-cy={props.dataCy || "no-fallback"}>
          
        <img
          src={props.fallbackIcon || noJobsFallbackIcon}
          alt="Fallback icon"
        />

        <div className=" padding-top-larger font-family-RHD-medium font-size-larger text-align-center">
          {props.title}
        </div>

        <div className=" font-size-medium text-align-center padding-top-default">
          {props.description}
        </div>
      </div>

      {props.showComingSoon && (
        <div
          style={{ height: "44px" }}
          className=" inherit-parent-width flex-justify-content-center flex-align-items-center border-1px-e5e5e5 border-radius-2px  font-size-medium font-color-tertiary">
          COMING SOON
        </div>
      )}
    </div>
  );
}
