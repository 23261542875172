import store from "../store";
import { actionTypes } from "./saga";

export function setAnnouncements(data) {
  store.dispatch({
    type: actionTypes.PUT_ANNOUNCEMENTS_DATA,
    payload: {
      data: data,
    },
  });
}

export function createAnnouncements(data, proof) {
  store.dispatch({
    type: actionTypes.CREATE_ANNOUNCEMENTS,
    payload: {
      summary: data,
      proof: proof,
    },
  });
}

export function getAnnouncementAssets(data) {
  store.dispatch({
    type: actionTypes.GET_ANNOUNCEMENT_ASSETS,
    payload: {
      data: data,
    },
  });
}
