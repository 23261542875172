import React from "react";
import "./Header.css";
import {
  ArrowIcon,
  ExitIcon,
  LanguageIcon,
  ProfileIcon,
} from "../../Assets/assets";
import ConditionalRender from "../ConditionalRender/ConditionalRender";
import appLogo from "../../Assets/appLogo.png";
import NavBar from "../NavBar/NavBar";

export default function Header(props) {
  return (
    <div className="background-color-primary inherit-parent-width">
      <div className="height-64px background-color-primary inherit-parent-width display-flex flex-align-items-center padding-left-large padding-right-large">
        <div className="flex-justify-content-space-between inherit-parent-width flex-align-items-center">
          <div className="display-flex flex-align-items-center flex-justify-content-center">
            {props.backOnClick ? (
              <div
                className="display-flex flex-align-items-center flex-justify-content-center rotate-180deg"
                data-cy="back-button"
                onClick={props.backOnClick}
              >
                <ArrowIcon width="20" height="20" color="#FFFFFF" />
              </div>
            ) : (
              // <div className="border-radius-50-percentage background-color-4e6be9 height-width-40px"/>
              <img src={appLogo} alt="app logo" className="height-width-40px" />
            )}
            <div className="display-flex flex-direction-column">
              <div className="padding-left-default font-color-white font-family-RHD-medium display-flex flex-align-items-center">
                {props.title || props.languageData.arunNehru}
              </div>
              {!props.title && (
                <div className="padding-left-default font-color-white font-family-RHD-medium display-flex flex-align-items-center font-size-small padding-top-small">
                  {props.languageData.arunNehruDescription}
                </div>
              )}
            </div>
          </div>

          <div className="display-flex">
            <ConditionalRender
              condition={typeof props.languageOnClick === "function"}
            >
              <div
                className="background-color-4e6be9 flex-justify-content-center flex-align-items-center height-width-40px"
                data-cy={"language-button"}
                onClick={() => {
                  props.languageOnClick();
                }}
              >
                <LanguageIcon />
              </div>
            </ConditionalRender>

            <ConditionalRender
              condition={typeof props.profileOnClick === "function"}
            >
              <div
                className="background-color-4e6be9 flex-justify-content-center flex-align-items-center margin-left-medium height-width-40px"
                data-cy={"profile-button"}
                onClick={() => {
                  props.profileOnClick();
                }}
              >
                <ProfileIcon />
              </div>
            </ConditionalRender>

            <ConditionalRender
              condition={typeof props.logoutOnClick === "function"}
            >
              <div
                className="background-color-4e6be9 flex-justify-content-center flex-align-items-center margin-left-medium height-width-40px"
                data-cy={"logout-header-button"}
                onClick={() => {
                  props.logoutOnClick();
                }}
              >
                <ExitIcon color="#FFFFFF" width="20" height="20" />
              </div>
            </ConditionalRender>
          </div>
        </div>
      </div>

      <ConditionalRender
        condition={
          typeof props.setDisplayScreen === "function" &&
          (window.matchMedia("(display-mode: standalone)").matches ||
            process.env.REACT_APP_STAGING !== "production")
        }
      >
        <NavBar
          setScreen={(screen) => {
            props.setDisplayScreen(screen);
          }}
          screen={props.navigation?.screen}
          languageData={props.languageData}
        />
      </ConditionalRender>
    </div>
  );
}
