import { all, put, takeEvery } from "redux-saga/effects";
import { errorHandler } from "../../Services/error";
import { removeStatus } from "./action";
import { logout } from "../authentication/actions";
import LogRocket from "logrocket";

export const statusActionTypes = {
  SET_ERROR_STATUS: "SET_ERROR_STATUS",
  SET_SUCCESS_STATUS: "SET_SUCCESS_STATUS",
  CLEAR_STATUS: "CLEAR_STATUS",
};

function* setErrorStatusWorker(action) {
  try {
    LogRocket.captureMessage(action.payload.message, {
      tags: {
        userType: "employee",
      },
      extra: {
        fatal: false,
        code: action.payload.code,
      },
    });
    const error = errorHandler(action.payload);

    yield put({
      type: "SET_STATUS",
      payload: {
        code: error.code,
        message: error.message,
      },
    });
    setTimeout(() => {
      removeStatus();
    }, 4000);

    if (
      action.payload.code === "permission-denied" ||
      action.payload.message === "Missing or insufficient permissions."
    ) {
      logout();
    }
  } catch (error) {}
}

function* setSuccessStatusWorker(action) {
  try {
    yield put({
      type: "SET_STATUS",
      payload: {
        code: null,
        message: action.payload.message,
      },
    });
    setTimeout(() => {
      removeStatus();
    }, 4000);
  } catch (error) {}
}

function* setStatusResponseWorker() {
  yield put({
    type: "REMOVE_STATUS",
  });
}

export default function* statusWatcher() {
  yield all([
    takeEvery("SET_ERROR_STATUS", setErrorStatusWorker),
    takeEvery("SET_SUCCESS_STATUS", setSuccessStatusWorker),
    takeEvery("CLEAR_STATUS", setStatusResponseWorker),
  ]);
}
