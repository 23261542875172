import { createStore, applyMiddleware, compose } from "redux";
import { persistStore } from "redux-persist";
import createSagaMiddleware from "redux-saga";
import LogRocket from "logrocket";
import { persistedReducer } from "./rootReducer";
import rootSaga from "./rootSaga";

const sagaMiddleware = createSagaMiddleware();

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  persistedReducer,
  composeEnhancer(applyMiddleware(sagaMiddleware, LogRocket.reduxMiddleware()))
);

sagaMiddleware.run(rootSaga);
export const persistor = persistStore(store);
export default store;
