import store from "../store";
import { actionTypes } from "./saga";

export function setPetitions(data) {
  store.dispatch({
    type: actionTypes.PUT_PETITIONS_DATA,
    payload: {
      data: data,
    },
  });
}

export function createPetitions(data, proof) {
  store.dispatch({
    type: actionTypes.CREATE_PETITIONS,
    payload: {
      summary: data,
      proof: proof,
    },
  });
}

export function setSelectedPetition(petitionId) {
  store.dispatch({
    type: actionTypes.PUT_SELECTED_PETITION,
    payload: {
      petitionId: petitionId,
    },
  });
}

export function getPetitionAssets(data) {
  store.dispatch({
    type: actionTypes.GET_PETITION_ASSETS,
    payload: {
      data: data,
    },
  });
}

export function setNewComplaintData(status, petitionId) {
  store.dispatch({
    type: actionTypes.SET_NEW_COMPLAINT_DATA,
    payload: {
      status: status,
      petitionId: petitionId,
    },
  });
}
