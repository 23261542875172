import { api } from "../Utils/constants";
import { setErrorStatus } from "../Redux/status/action";

export async function checkAccountAvailability(phoneNumber) {
  const response = await fetch(
    `${api.baseUrl}/profile/isExists?phoneNumber=${encodeURIComponent(
      phoneNumber
    )}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function raisePetition(data, accessToken) {
  const response = await fetch(`${api.baseUrl}/petition`, {
    method: "POST",
    headers: {
      Accept: "*/*",
      "x-auth-token": `Bearer ${accessToken}`,
    },
    body: data,
  });
  const result = await response.json();
  if (result.error) {
    throw result.error;
  }
  return result;
}

export async function aadhaarAuthentication(data, accessToken, type) {
  const response = await fetch(`${api.baseUrl}/citizen/aadhaar/${type}`, {
    method: "POST",
    headers: {
      Accept: "*/*",
      "x-auth-token": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const result = await response.json();
  if (result.error) {
    throw result.error;
  }
  return result;
}
