import React from "react";
import { isValidObject } from "../../Services/validators";
import ConditionalRender from "../ConditionalRender/ConditionalRender";
import Fallback from "../Fallback/Fallback";
import { ArrowIcon, InfoIcon, LocationIcon } from "../../Assets/assets";
import { dateAndTimeConverter } from "../../Utils/constants";
import defaultJobIcon from "../../Assets/defaultJobIcon.svg";

export default function Jobs(props) {
  return (
    <>
      <ConditionalRender
        condition={
          (props.jobs?.data && Object.values(props.jobs?.data)?.length === 0) ||
          props.jobs.data === null
        }>
        <div
          className="inherit-parent-height flex-justify-content-space-between flex-direction-column "
          data-cy={`jobs-screen`}>
          <Fallback
            title={props.languageData.noJobsPosted}
            description={props.languageData.noJobsFallbackDescription}
            dataCy="no-Jobs-fallback"
            // showComingSoon={true}
          />
        </div>
      </ConditionalRender>

      <ConditionalRender condition={isValidObject(props.jobs.data)}>
        {props.jobs.data &&
          Object.values(props.jobs.data)
            .sort((a, b) => {
              return b.createdAt - a.createdAt;
            })
            .map((data, index) => (
              <React.Fragment key={index}>
                <JobCard
                  data={data}
                  cardClick={(documentId) => {
                    props.navigate(`/jobs/${documentId}`);
                  }}
                  language={props.profile.language}
                  languageData={props.languageData}
                />
              </React.Fragment>
            ))}
      </ConditionalRender>
    </>
  );
}

const JobCard = (props) => {
  return (
    <div
      onClick={() => {
        props.cardClick(props.data?.documentId);
      }}
      style={{
        height: "102px",
      }}
      data-cy={`${props.data?.documentId}-volunteering`}
      className="inherit-parent-width display-flex margin-bottom-large gradient-border">
      <div className="height-width-100px">
        {props.data?.company?.logoSrc?.url ? (
          <img
            className="height-width-100px"
            style={{
              borderRadius: "4px 0px 0px 4px",
              paddingLeft: "2px",
              paddingTop: "2px",
              objectFit: "fill",
            }}
            src={props.data?.company?.logoSrc?.url || ""}
            alt="volunteering-thumbnail"
          />
        ) : (
          <div className="inherit-parent-width inherit-parent-height flex-justify-content-center flex-align-items-center">
            <img
              src={defaultJobIcon}
              alt="job icon"
              style={{
                borderRadius: "2px",
                paddingLeft: "2px",
                objectFit: "fill",
                width: "70px",
                height: "70px",
              }}
            />
          </div>
        )}
      </div>
      <div
        className=" display-flex padding-medium"
        style={{ width: "calc(100% - 100px)" }}>
        <div className="" style={{ width: "calc(100% - 16px)" }}>
          <div className=" display-flex flex-align-items-center">
            <div
              className=" font-size-small white-space-no-wrap text-overflow-ellipsis "
              style={{ maxWidth: "50%" }}>
              {props.data.contact.name[props.language]}
            </div>
            <div className=" dot-4px-quaternary margin-horizontal-default" />
            <div className=" font-size-small  white-space-no-wrap text-overflow-ellipsis">
              {dateAndTimeConverter(props.data.createdAt, "secondDate")}
            </div>
          </div>

          <div
            className={` ${
              props.data.status.currentStatus === "CLOSED"
                ? "font-color-quaternary"
                : ""
            } white-space-no-wrap text-overflow-ellipsis font-family-RHD-medium padding-top-default`}>
            {props.data.title[props.language]}
          </div>

          <div className="white-space-no-wrap text-overflow-ellipsis font-family-RHD-medium font-size-medium display-flex flex-align-item-center padding-top-default">
            <ConditionalRender
              condition={props.data.status.currentStatus === "OPEN"}>
              <LocationIcon width="16" height="16" color={"#868686"} />{" "}
              <div className=" padding-left-small white-space-no-wrap text-overflow-ellipsis font-size-small font-color-quaternary">
                {" "}
                {props.data.location.address[props.language]},{" "}
                {props.data.location.city[props.language]}
              </div>
            </ConditionalRender>

            <ConditionalRender
              condition={props.data.status.currentStatus === "CLOSED"}>
              <InfoIcon color={"#868686"} />{" "}
              <div className=" padding-left-small white-space-no-wrap text-overflow-ellipsis font-size-small font-color-quaternary">
                {props.languageData.noAcceptingApplicants}
              </div>
            </ConditionalRender>
          </div>
        </div>

        <ArrowIcon />
      </div>
    </div>
  );
};
