import React from "react";
import "./NavBar.css";

export default function NavBar(props) {
  const data = [
    {
      title: props.languageData?.home,
      id: "home",
    },
    {
      title: props.languageData?.petitions,
      id: "petitions",
    },
    {
      title: props.languageData?.jobs,
      id: "jobs",
    },
    {
      title: props.languageData?.volunteering,
      id: "volunteering",
    },
  ];

  return (
    <div
      className="background-color-primary inherit-parent-width flex-justify-content-center overflow-scroll hide-scroll-bar"
      data-cy="nav-bar"
    >
      <div className=" inherit-parent-width display-flex max-width-500px ">
        {data.map((data, index) => (
          <React.Fragment key={index}>
            <div
              className={`display-flex flex-align-items-center flex-direction-column padding-horizontal-larger  ${
                props.screen === data.id
                  ? "nav-bar-selected-background border-bottom-white-2px font-color-white"
                  : "font-color-grey"
              } `}
              style={{ height: "38px" }}
              data-cy={
                props.screen === data.id
                  ? `current-screen-${data.id}`
                  : `navigate-to-${data.id}`
              }
              onClick={() => {
                props.setScreen(data.id);
              }}
            >
              <div className="inherit-parent-height display-flex flex-align-items-center white-space-no-wrap">
                {data.title}
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
