import React from "react";
import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import VolunteeringFallbackIcon from "../../Assets/VolunteeringFallbackIcon.svg";

export default function OptInModal(props) {
  return (
    <Modal
      show={true}
      canIgnore={true}
      onClose={() => {
        props.navigate(-1);
      }}
      width="inherit-parent-width"
      position=" absolute-center-self-horizontally bottom-0 "
      maxWidth="max-width-630px"
      background="false"
      boxShadow="false"
      borderRadius="false"
      height="height-fit-to-content"
    >
      <div
        className="font-color-secondary font-family-gilroy-medium font-color-secondary text-align-center padding-larger background-white"
        data-cy={"optIn-confirmation-modal"}
      >
        {props.showIcon ? (
          <img
            src={VolunteeringFallbackIcon}
            alt="volunteering icon"
            className=" padding-bottom-default"
          />
        ) : (
          <></>
        )}
        <div className=" font-family-RHD-medium font-size-medium font-size-large">
          {props.languageData?.optInVolunteerRequests.toUpperCase()}
        </div>
        <div className=" padding-top-default">
          {props.languageData?.confirmOptInDescription}
        </div>

        <div className="display-flex padding-top-larger ">
          <Button
            boxShadow={false}
            type="submit"
            // variant="danger"
            text={props.languageData?.yes}
            className={"border-1px-e5e5e5 margin-right-small"}
            onClick={() => {
              props.updateOpting("OPTEDIN");
              props.navigate(-1);
            }}
            data-cy={"confirm-optIn-button"}
          />
          <Button
            boxShadow={false}
            text={props.languageData?.no}
            variant={"secondary"}
            onClick={() => {
              props.navigate(-1);
            }}
            className={
              "font-color-secondary border-1px-e5e5e5 margin-left-small"
            }
            data-cy={"cancel-optIn-button"}
          />
        </div>
      </div>
    </Modal>
  );
}
