import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Route, Routes, useParams } from "react-router-dom";
import { dateAndTimeConverter } from "../../Utils/constants";
import { ImageFallbackIcon, PdfFileIcon } from "../../Assets/assets";
import { isValidArray, isValidObject } from "../../Services/validators";
import ConditionalRender from "../../Components/ConditionalRender/ConditionalRender";
import Header from "../../Components/Header/Header";
import { setErrorStatus } from "../../Redux/status/action";
import ViewAssetModal from "../../Components/ViewAssetModal/ViewAssetModal";

function SelectedPetition(props) {
  const { petitionId } = useParams("petitionId");
  const [petitionData, setPetitionData] = useState(null);
  const [previewFile, setPreviewFile] = useState({
    show: false,
    type: null,
    data: null,
  });
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [PDFArrayBuffer, setPDFArrayBuffer] = useState([]);

  useEffect(() => {
    if (props.petitions.data[petitionId]) {
      setPetitionData(props.petitions.data?.[petitionId]);
    } else {
      setPetitionData(null);
    }
  }, [props.petitions.data, petitionId]);

  useEffect(() => {
    if (
      petitionId &&
      isValidObject(props.petitions.data?.[petitionId]?.fileSrc)
    ) {
      updatePDFArrayBuffer(petitionId, props.petitions.data);
    }
    // eslint-disable-next-line
  }, [petitionId, props.petitions.data]);

  const updatePDFArrayBuffer = async (selectedPetition, data) => {
    const pdfUrls = data[selectedPetition]?.fileSrc.pdfs;

    try {
      const arrayBuffers = await Promise.all(
        pdfUrls.map((url) => {
          return getPdfArrayBufferData(url);
        })
      );
      setPDFArrayBuffer(arrayBuffers);
    } catch (error) {
      console.error("Failed to fetch one or more PDF data:", error);
    }
  };

  return (
    <>
      <Header
        languageData={props.languageData}
        title={props.languageData.viewPetition}
        backOnClick={() => {
          props.navigate("/");
        }}
      />
      <div className="remaining-body-height background-white-half flex-justify-content-center padding-large font-family-RHD-medium overflow-scroll">
        <div className=" max-width-500px inherit-parent-width">
          <div className=" display-flex flex-justify-content-space-between flex-align-items-center">
            <div className=" font-size-medium ">
              #{petitionData?.documentId}
            </div>

            <div
              data-cy={`status-${petitionData?.status.currentStatus}`}
              className="  padding-top-small padding-bottom-small padding-left-default padding-right-default border-radius-default font-size-small background-color-grey width-fit-content">
              {petitionData?.status.currentStatus}
            </div>
          </div>

          <div className=" margin-top-large background-color-grey padding-default">
            <ConditionalRender condition={petitionData?.description}>
              <div>{petitionData?.description}</div>
            </ConditionalRender>

            {(isValidArray(petitionData?.fileSrc?.images) ||
              isValidArray(petitionData?.fileSrc?.pdfs)) && (
              <div
                style={{ minHeight: "56px", minWidth: "300px" }}
                className="inherit-parent-height inherit-parent-width display-flex margin-top-medium ">
                {isValidArray(petitionData?.fileSrc?.images) &&
                  petitionData.fileSrc?.images?.map((url, index) => (
                    <div
                      key={index}
                      className={`height-width-56px margin-bottom-default  margin-right-large ${
                        petitionData.fileSrc?.images.length !== index + 1
                          ? "margin-right-default"
                          : " "
                      } `}
                      onClick={() => {
                        setPreviewFile({
                          show: true,
                          type: "image",
                          data: url,
                        });
                        props.navigate(
                          `/petitions/${petitionData?.documentId}/viewAsset`
                        );
                      }}>
                      <img
                        className="border-radius-default inherit-parent-height inherit-parent-width"
                        data-cy={`proof-image-${index}`}
                        src={url}
                        alt="images"
                      />
                    </div>
                  ))}
                {isValidArray(petitionData?.fileSrc?.pdfs) &&
                  petitionData?.fileSrc?.pdfs?.map((_data, index) => (
                    <div
                      key={index}
                      data-cy={`proof-pdf-${index}`}
                      style={{
                        height: "56px",
                        width: "56px",
                        backgroundColor: "#757575",
                      }}
                      onClick={() => {
                        setPreviewFile({
                          show: true,
                          type: "pdf",
                          data: index,
                        });
                        props.navigate(
                          `/petitions/${petitionData?.documentId}/viewAsset`
                        );
                      }}
                      className="border-radius-default margin-right-large font-color-dark flex-align-items-center flex-justify-content-center">
                      <PdfFileIcon height={40} width={40} />
                    </div>
                  ))}
              </div>
            )}

            <ConditionalRender
              condition={
                isValidArray(petitionData?.assets) &&
                !isValidArray(petitionData?.fileSrc?.images) &&
                !isValidArray(petitionData?.fileSrc?.pdfs)
              }>
              <div className="height-width-56px margin-top-medium border-radius-default background-color-black-with-opacity-light display-flex flex-justify-content-center flex-align-items-center">
                <ImageFallbackIcon />
              </div>
            </ConditionalRender>

            <div className=" font-size-small padding-top-default font-color-tertiary">
              {dateAndTimeConverter(petitionData?.createdAt, "secondDate")} .{" "}
              {dateAndTimeConverter(petitionData?.createdAt, "Time")}
            </div>
          </div>

          {isValidArray(petitionData?.latestUpdate) &&
            petitionData?.latestUpdate
              ?.sort((a, b) => a.updatedAt - b.updatedAt)
              .map((data, index) => (
                <div key={index} className="padding-top-larger">
                  <UpdateCards data={data} language={props.profile.language} />
                </div>
              ))}
        </div>
      </div>

      <Routes>
        <Route
          path="viewAsset"
          element={
            <ViewAssetModal
              previewFile={previewFile}
              PDFArrayBuffer={PDFArrayBuffer}
              onPDFChange={() => {
                if (isPdfLoading === false) {
                  setIsPdfLoading(true);
                }
              }}
              onPdfRender={() => {
                if (isPdfLoading === true) {
                  setIsPdfLoading(false);
                }
              }}
              navigate={props.navigate}
            />
          }
        />
      </Routes>
    </>
  );
}

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    profile: state.profile,
    petitions: state.petitions,
  };
};

const mapDispatchToProps = function () {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectedPetition);

const getPdfArrayBufferData = async (url) => {
  try {
    const res = await fetch(url);
    if (!res.ok) {
      throw new Error("Network response was not ok");
    }
    const arrayBuffer = await res.arrayBuffer();
    return arrayBuffer;
  } catch (error) {
    setErrorStatus({
      custom: "custom",
      message: "Unable to download",
    });
    console.error(error);
  }
};

const UpdateCards = (props) => {
  return (
    <div
      style={{ backgroundColor: "rgb(202 212 255)" }}
      className="display-flex padding-default flex-direction-column font-color-tertiary">
      <div>{props.data?.[props.language]?.description}</div>
      <div className=" font-size-small padding-top-default">
        {dateAndTimeConverter(props.data?.updatedAt, "secondDate")} .{" "}
        {dateAndTimeConverter(props.data?.updatedAt, "Time")}
      </div>
    </div>
  );
};
