const petitionReducer = (
  state = {
    loading: false,
    data: null,
    selectedPetition: null,
    petitionId: null,
    isPetitionSubmitted: null,
  },
  action
) => {
  switch (action.type) {
    case "SET_PETITIONS_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };

    case "SET_PETITIONS_DATA":
      return {
        ...state,
        data: action.payload.data,
      };

    case "SET_SELECTED_PETITION":
      return {
        ...state,
        selectedPetition: action.payload.petitionId,
      };

    case "SET_PETITION_ASSETS":
      return {
        ...state,
        data: { ...state.data, ...action.payload },
      };

    case "UPDATE_NEW_COMPLAINT_DATA":
      return {
        ...state,
        isPetitionSubmitted: action.payload.status,
        petitionId: action.payload.petitionId,
      };

    case "RESET":
      return {
        loading: false,
        data: null,
        selectedPetition: null,
        petitionId: null,
        isPetitionSubmitted: null,
      };

    default:
      return state;
  }
};
export default petitionReducer;
