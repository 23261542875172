import React, { useEffect, useState } from "react";
import InputBox from "../../Components/InputBox/InputBox";
import OTPInputBox from "../../Components/OTPInputBox/OTPInputBox";
import Button from "../../Components/Button/Button";
import { connect } from "react-redux";
import {
  clearRegisterCredentials,
  sendAadhaarOtp,
  verifyAadhaarOtp,
} from "../../Redux/authentication/actions";
import { Regex } from "../../Utils/constants";
import ConditionalRender from "../../Components/ConditionalRender/ConditionalRender";
import { isValidObject } from "../../Services/validators";
import SuccessScreen from "../../Components/SuccessScreen/SuccessScreen";
import Header from "../../Components/Header/Header";
import { Route, Routes } from "react-router-dom";
import LogoutModal from "../../Components/LogoutModal/LogoutModal";
import LanguageModal from "../../Components/LanguageModal/LanguageModal";

function Register(props) {
  const [registerFormData, setRegisterFormData] = useState({
    aadhaarNumber: "",
    OTP: null,
  });

  useEffect(() => {
    if (
      isValidObject(props.profile.data) &&
      !props.auth.register.transactionId
    ) {
      props.navigate("/");
    }

    // eslint-disable-next-line
  }, [props.profile.data, props.auth.register]);

  return (
    <>
      <Header
        languageData={props.languageData}
        languageOnClick={() => {
          props.navigate("/register/languages");
        }}
        logoutOnClick={() => {
          props.navigate("/register/logout");
        }}
      />
      <div className="remaining-body-height inherit-parent-width display-flex flex-justify-content-center flex-direction-column">
        <ConditionalRender
          condition={
            props.profile.data === null || props.profile.loading === true
          }
        >
          <div className=" flex-align-items-center flex-justify-content-center inherit-parent-height inherit-parent-width">
            <div className="qr-loader" data-cy="qr-loader" />
          </div>
        </ConditionalRender>
        <ConditionalRender
          condition={
            props.profile.data !== null &&
            !isValidObject(props.profile.data) &&
            props.profile.loading === false
          }
        >
          <div className="flex-justify-content-center inherit-parent-height flex-align-items-center flex-direction-column ">
            <div className=" height-64px" />
            <div className=" padding-horizontal-larger">
              <div className=" padding-top-default text-align-center font-family-RHD-medium font-size-large">
                {props.languageData.registerNewUser}
              </div>
              <div className=" padding-top-medium text-align-center font-size-small">
                {props.languageData.registerNewUserDescription}
              </div>
            </div>

            <form
              className="inherit-parent-height inherit-parent-width max-width-500px flex-justify-content-space-between flex-direction-column"
              onChange={(event) => {
                if (event.target.name === "aadhaarNumber") {
                  if (props.auth.register.transactionId) {
                    props.clearRegisterCredentials();
                  }
                  setRegisterFormData({
                    ...registerFormData,
                    [event.target.name]: event.target.value,
                  });
                }
              }}
              onSubmit={(event) => {
                event.preventDefault();
                props.verifyAadhaarOtp(registerFormData.OTP);
                setRegisterFormData({
                  aadhaarNumber: "",
                  OTP: null,
                });
              }}
            >
              <div className=" background-white padding-horizontal-larger">
                <div className="height-64px" />
                <InputBox
                  className="inherit-parent-width border-radius-default margin-bottom-large"
                  name="aadhaarNumber"
                  label={props.languageData.aadhaarNumber}
                  type="number"
                  autoFocus={true}
                  autoComplete="off"
                  value={
                    props.auth.register.aadhaarNumber ||
                    registerFormData.aadhaarNumber
                  }
                  buttonTileId="aadhaar-send-otp-button"
                  buttonTile={
                    props.auth.register.transactionId !== null
                      ? props.languageData?.resendOTP
                      : props.languageData?.getOTP
                  }
                  buttonType={
                    typeof props.auth.register.transactionId === "string"
                      ? "button"
                      : "submit"
                  }
                  onButtonClick={(event) => {
                    event.preventDefault();

                    props.sendAadhaarOtp(
                      props.auth.register.aadhaarNumber ||
                        registerFormData.aadhaarNumber
                    );
                  }}
                  data-cy="register-screen-aadhaar-number-field"
                  required
                  suffixButtonDisabled={
                    props.auth.loading ||
                    (!Regex.aadhaarNumber.test(
                      registerFormData.aadhaarNumber
                    ) &&
                      !Regex.aadhaarNumber.test(
                        props.auth.register.aadhaarNumber
                      ))
                  }
                  validation={(aadhaarNumber) => {
                    if (!Regex.aadhaarNumber.test(aadhaarNumber)) {
                      return {
                        status: false,
                        message: props.languageData.enterValidAadhaarNumber,
                      };
                    }
                  }}
                />
                {props.auth.register.transactionId && (
                  <OTPInputBox
                    name="otp"
                    label={props.languageData?.pleaseEnterOTP}
                    setValue={(otp) => {
                      setRegisterFormData({
                        ...registerFormData,
                        OTP: otp,
                      });
                    }}
                    className="margin-bottom-large"
                    required
                  />
                )}
              </div>
              <footer className="padding-larger inherit-parent-width max-width-500px">
                <Button
                  data-cy={
                    props.auth.loading ? "login-loading-button" : "login-button"
                  }
                  loading={props.auth.loading}
                  type={
                    typeof props.auth.register?.transactionId === "string"
                      ? "submit"
                      : "button"
                  }
                  className=""
                  text={props.languageData.completeRegistration}
                  disabled={
                    !(
                      typeof registerFormData.OTP === "string" &&
                      registerFormData.OTP.length === 6
                    )
                  }
                  boxShadow={false}
                />
              </footer>
            </form>
          </div>
        </ConditionalRender>

        <ConditionalRender
          condition={
            isValidObject(props.profile.data) &&
            props.auth.register.transactionId
          }
        >
          <SuccessScreen
            continueOnClick={() => {
              props.clearRegisterCredentials();
            }}
            text={
              <>
                {props.languageData.registerSuccessfully.line1}
                <br /> {props.languageData.registerSuccessfully.line2}
              </>
            }
            buttonText={props.languageData?.okay}
          />
        </ConditionalRender>
      </div>

      <Routes>
        <Route
          path="languages"
          element={
            <LanguageModal
              languageData={props.languageData}
              languageList={props.languageList}
              navigate={props.navigate}
              changeLanguage={props.changeLanguage}
              profile={props.profile}
            />
          }
        />
        <Route
          path="logout"
          element={
            <LogoutModal
              languageData={props.languageData}
              navigate={props.navigate}
              profile={props.profile}
              logout={props.logout}
            />
          }
        />
      </Routes>
    </>
  );
}

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    profile: state.profile,
  };
};

const mapDispatchToProps = function () {
  return {
    sendAadhaarOtp: (aadhaarNumber) => sendAadhaarOtp(aadhaarNumber),
    verifyAadhaarOtp: (otp) => verifyAadhaarOtp(otp),
    clearRegisterCredentials: () => clearRegisterCredentials(),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
