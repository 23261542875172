import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { dateAndTimeConverter, openInNewTab } from "../../Utils/constants";
import { InfoIcon, LocationIcon } from "../../Assets/assets";
import ConditionalRender from "../../Components/ConditionalRender/ConditionalRender";
import Header from "../../Components/Header/Header";
import defaultJobIcon from "../../Assets/defaultJobIcon.svg";

function SelectedJob(props) {
  const { jobId } = useParams("jobId");
  const [jobData, setJobData] = useState(null);

  useEffect(() => {
    if (props.jobs.data?.[jobId]) {
      setJobData(props.jobs.data?.[jobId]);
    } else {
      setJobData(null);
    }
  }, [props.jobs, jobId]);

  return (
    <>
      <Header
        languageData={props.languageData}
        title={props.languageData.viewJob}
        backOnClick={() => {
          props.navigate("/");
        }}
      />
      <div className="remaining-body-height background-white-half flex-justify-content-center padding-large font-family-RHD-medium overflow-scroll">
        <div className=" max-width-500px inherit-parent-width">
          <TitleCard
            data={jobData}
            language={props.profile.language}
            status={jobData?.status.currentStatus}
          />

          <ConditionalRender
            condition={jobData?.status.currentStatus === "OPEN"}
          >
            <div className=" margin-top-large background-color-grey padding-medium">
              <div>
                {props.languageData?.coordinator}:{" "}
                {jobData?.contact.name[props.profile.language]}
              </div>
              <div>
                {" "}
                {props.languageData?.phone}: {jobData?.contact.phoneNumber}{" "}
              </div>
              {jobData?.contact.email && (
                <div>
                  {props.languageData?.email}:{" "}
                  <span
                    onClick={() => {
                      window.open(`mailto:${jobData.contact.email}`);
                    }}
                    className="font-color-primary"
                  >
                    {jobData.contact.email}
                  </span>
                </div>
              )}
              {jobData?.contact.link && (
                <div>
                  {props.languageData?.link}:{" "}
                  <span
                    onClick={() => {
                      openInNewTab(`https://${jobData.contact.link}`);
                    }}
                    className=" font-color-primary"
                  >
                    {jobData.contact.link}
                  </span>
                </div>
              )}
              <div className=" padding-top-larger font-size-medium">
                {props.languageData?.contactCoordinator}
              </div>
            </div>
          </ConditionalRender>

          <ConditionalRender
            condition={jobData?.status.currentStatus === "CLOSED"}
          >
            <div className=" margin-top-large background-color-FFF4EA font-color-CB6F19 padding-medium display-flex flex-align-items-center">
              {" "}
              <InfoIcon color="#CB6F19" />{" "}
              <span className=" padding-left-default">
                {props.languageData.noAcceptingApplicants}
              </span>{" "}
            </div>
          </ConditionalRender>

          <div
            className={`${
              jobData?.status.currentStatus === "CLOSED"
                ? "font-color-quaternary"
                : ""
            } margin-top-large background-color-grey padding-medium`}
          >
            <ConditionalRender condition={jobData?.description}>
              <div>{props.languageData.description}:</div>
              <div>{jobData?.description[props.profile.language]}</div>
            </ConditionalRender>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    profile: state.profile,
    jobs: state.jobs,
  };
};

const mapDispatchToProps = function () {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectedJob);

const TitleCard = (props) => {
  return (
    <div
      onClick={() => {
        props.cardClick(props.data?.documentId);
      }}
      data-cy={`${props.data?.documentId}-volunteering-title-card`}
      className="inherit-parent-width display-flex margin-bottom-large background-color-grey"
      style={{
        minHeight: "100px",
      }}
    >
      <div className="height-width-100px">
        {props.data?.company?.logoSrc?.url ? (
          <img
            className="height-width-100px"
            style={{
              borderRadius: "4px 0px 0px 4px",
              //   paddingLeft: "2px",
              //   paddingTop: "2px",
              objectFit: "fill",
            }}
            src={props.data?.company?.logoSrc?.url || ""}
            alt="volunteering-thumbnail"
          />
        ) : (
          <div className="inherit-parent-width inherit-parent-height flex-justify-content-center flex-align-items-center">
            <img
              src={defaultJobIcon}
              alt="job icon"
              style={{
                borderRadius: "2px",
                paddingLeft: "2px",
                objectFit: "fill",
                width: "70px",
                height: "70px",
              }}
            />
          </div>
        )}
      </div>
      <div
        className=" display-flex padding-medium"
        style={{ width: "calc(100% - 100px)" }}
      >
        <div className="">
          <div className=" display-flex flex-align-items-center font-color-quaternary">
            <div className=" font-size-small ">
              {props.data?.contact.name[props.language]}
            </div>
            <div className=" dot-4px margin-horizontal-default" />
            <div className=" font-size-small  ">
              {dateAndTimeConverter(props.data?.createdAt, "secondDate")}
            </div>
          </div>

          <div
            className={` ${
              props.status === "CLOSED" ? "font-color-quaternary" : ""
            }  font-family-RHD-medium padding-top-default`}
          >
            {props.data?.title[props.language]}
          </div>

          <div className=" font-family-RHD-medium font-size-medium flex-align-item-center padding-top-default font-color-quaternary">
            <LocationIcon color={"#868686"} />{" "}
            {props.data?.location.address[props.language]},{" "}
            {props.data?.location.city[props.language]}
          </div>
        </div>

        {/* <ArrowIcon /> */}
      </div>
    </div>
  );
};
